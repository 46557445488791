import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"

import {
  EXPERIMENTAL_JOB,
  EXTRA_JOB,
  isValidJob,
  STATUS_FAILED,
  STATUS_FINISHED,
  STATUS_KILLED,
  STATUS_RUNNING,
  UPLOAD_JOB
} from "../constants/jobnames"
import { getCookie, isAuth, removeCookie, removeLocalStorage } from "../helpers/auth"
import ConsoleHelper from "../helpers/ConsoleHelper"

let uploadSource = undefined
let isGettingProjects = false

axios.interceptors.response.use(
  response =>  response
  ,
  error => {
    if (error.response.status === 401) {
      removeCookie("token")
      removeLocalStorage("user")
      toast.error("Login session expired !")
      setTimeout(() => {
        if(!window.location.href.includes("/login")) {
          window.location.href = "/login"
        }
      }, 1000)
    }
    return Promise.reject(error)
  }
)

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    currentPID: "",
    currentProject: null,
    uploadID: undefined
  },
  reducers: {
    setCurrentPID: (state, action) => {
      ConsoleHelper("Setting project pid " + action.payload)
      state.currentPID = action.payload
    },
    setUploadID: (state, action) => {
      ConsoleHelper("Setting uploading ID " + action.payload)
      state.uploadID = action.payload
    },
    doJobSuccess: (state, action) => {
      ConsoleHelper("DoJobSuccess", action.payload)
      const jid = action.payload.jid
      const pid = action.payload.pid
      const iid = action.payload.iid
      const jobname = action.payload.jobname
      if (!isValidJob(jobname)) {
        ConsoleHelper("Error not recognized job [" + jid, "] - " + jobname)
        return
      }
      if (pid !== state.currentPID) {
        ConsoleHelper("Error job in wrong project [" + jid, "] - " + pid)
        return
      }
      if (iid !== undefined) {
        //ConsoleHelper('Image Job' + action.payload)
        if (state.currentProject.images[iid] === undefined) {
          if (action.payload.newImage !== undefined) {
            state.currentProject.images[iid] = action.payload.newImage
          } else {
            state.currentProject.images[iid] = { iid, name: iid, jobs: {} }
          }
        }
        if (state.currentProject.images[iid].jobs === undefined) state.currentProject.images[iid].jobs = {}
        if (jobname === UPLOAD_JOB) state.currentProject.images[iid].jobs = {}
        state.currentProject.images[iid].jobs[jobname] = {
          jid: jid,
          jobname,
          status: action.payload.status,
          progress: 0,
          parameters: action.payload?.parameters || null
        }
      }
    },
    killJobSuccess: (state, action) => {
      ConsoleHelper("killJobSuccess" + action.payload)
      const jid = action.payload.jid
      const pid = action.payload.pid
      const iid = action.payload.iid
      const jobname = action.payload.jobname
      if (!isValidJob(jobname)) {
        ConsoleHelper("Error not recognized job [" + jid, "] - " + jobname)
        return
      }
      if (pid !== state.currentPID) {
        ConsoleHelper("Error job in wrong project [" + jid, "] - " + pid)
        return
      }
      state.currentProject.images[iid].jobs[jobname].status = STATUS_KILLED
      state.currentProject.images[iid].jobs[jobname].progress = 0
    },
    getJobStatusSuccess: (state, action) => {
      ConsoleHelper("getJobStatusSuccess", action.payload)
      const jid = action.payload.jid
      const pid = action.payload.pid
      const iid = action.payload.iid
      const jobname = action.payload.jobname
      const target_iid = action.payload?.target_iid || ""
      if (!isValidJob(jobname)) {
        ConsoleHelper("Error not recognized job - " + jobname)
        return
      }
      if (pid !== state.currentPID) {
        ConsoleHelper("Error job in wrong project [" + jid, "] - " + pid)
        return
      }
      if (jobname === UPLOAD_JOB) {
        if (state.currentProject.images[iid] === undefined) {
          if (action.payload.type !== undefined)
            state.currentProject.images[iid] = {
              iid,
              filename: iid,
              type: action.payload.type,
              jobs: {}
            }
          else state.currentProject.images[iid] = { iid, filename: iid, jobs: {} }
          if (state.currentProject.images[iid].jobs === undefined) state.currentProject.images[iid].jobs = {}
          state.currentProject.images[iid].jobs[jobname] = {
            jid: jid,
            jobname,
            status: action.payload.status,
            progress: 0,
            target_iid
          }
        }
      } else {
        if (state.currentProject.images[iid].jobs === undefined) {
          ConsoleHelper("Error non existing job object pid [" + pid, "] - img " + iid)
          return
        }
      }
      if (!state.currentProject.images[iid].jobs[jobname]) state.currentProject.images[iid].jobs[jobname] = {}
      state.currentProject.images[iid].jobs[jobname].iid = iid
      state.currentProject.images[iid].jobs[jobname].status = action.payload.status
      state.currentProject.images[iid].jobs[jobname].progress = action.payload.progress
      state.currentProject.images[iid].jobs[jobname].parameters = action.payload?.parameters || null
      if (action?.payload?.real_iid) state.currentProject.images[iid].jobs[jobname].real_iid = action?.payload?.real_iid
    },
    getProjectsSuccess: (state, action) => {
      let projects = action.payload
      ConsoleHelper("getProjects", projects)
      // -----------------------------------------------------
      // Create job structures
      for (var pid in projects) {
        projects[pid].workflows = []
        if (projects[pid].images === undefined) projects[pid].images = {}
      }
      state.projects = { ...projects }
      // -----------------------------------------------------
      // Extra projects for visualization
      // const num = 4
      // for (var i = 0 i < num i++) {
      //     for (var ix = 0 ix < projects.length ix++) {
      //         projects[ix].index = ix + i * projects.length
      //     }
      //     state.projects = [...state.projects, ...JSON.parse(JSON.stringify(projects))]
      // }
      // -----------------------------------------------------
    },
    getCurrentProjectSuccess: (state, action) => {
      let projectData = action.payload
      ConsoleHelper("getCurrentProjectSuccess", projectData)
      // -----------------------------------------------------
      // Update Job
      let pid = projectData.pid
      if (pid !== state.currentPID) {
        ConsoleHelper("Error: Not defined project ")
      }
      if (state.currentProject !== null && state.currentProject.pid === state.currentPID) {
        for (var iid in state.currentProject.images) {
          if (projectData.images[iid] !== undefined) {
            projectData.images[iid].jobs = {
            ...state.currentProject.images[iid].jobs,
            ...projectData.images[iid].jobs
            }
          }
        }
        state.currentProject = { ...state.currentProject, ...projectData }
      } else {
        state.currentProject = { ...projectData }
      }
    },
    setCurrentProject: (state, action) => {
      let projectData = action.payload
      ConsoleHelper("getCurrentProjectSuccess", projectData)
      state.currentProject = { ...projectData }
    },
    uploadProjectSuccess: (state, action) => {
      let newProject = action.payload
      ConsoleHelper("Received new Project " + newProject)
      newProject.workflows = []
      state.projects[newProject.pid] = { ...newProject }
      if (state.currentPID === newProject.pid) state.currentProject = { ...newProject }
    },
    deleteProjectSuccess: (state, action) => {
      let pid = action.payload.pid
      ConsoleHelper("Deleted Project " + pid)
      delete state.projects[pid]
      state.currentPID = ""
      state.currentProject = null
    },
    addWorkflowSuccess: (state, action) => {
      let pid = action.payload.pid
      ConsoleHelper("New Workflow Project" + pid)
      if (pid !== state.currentPID) {
        ConsoleHelper("Error job in wrong project [" + jid, "] - " + pid)
        return
      }

      if (state.currentProject !== undefined) {
        let index = 0
        let _workflows = state.currentProject.workflows || []

        let last = _workflows.length
        if (last > 0) index = _workflows[last - 1].index + 1
        let nWorkflow = {
          name: " W-" + (index + 1),
          index: index
        }
        ConsoleHelper(" New added workflow: " + nWorkflow)
        _workflows.push(nWorkflow)
        state.currentProject.workflows = _workflows
      }
    },
    deleteWorkflowSuccess: (state, action) => {
      let pid = action.payload.pid
      if (pid !== state.currentPID) {
        ConsoleHelper("Error job in wrong project [" + jid, "] - " + pid)
        return
      }
      ConsoleHelper("Delete Workflow Project" + pid)
      if (state.currentProject !== undefined) {
        let index = action.payload.index
        if (state.currentProject.workflows[index] !== undefined) {
          state.currentProject.workflows.splice(index, 1)
        }
      }
    },
    deleteExpSuccess: (state, action) => {
      if (state.currentProject.images[action.payload.vid] !== undefined) {
        delete state.currentProject.images[action.payload.vid].jobs[action.payload.index]
        if (action.payload.elength == 1) delete state.currentProject.images[action.payload.vid].jobs[EXPERIMENTAL_JOB]
      }
    },
    failedJob: (state, action) => {
      ConsoleHelper("failedJob ", action.payload)
      const iid = action.payload.iid
      const pid = action.payload.pid
      const jobname = action.payload.jobname

      if (!isValidJob(jobname)) {
        ConsoleHelper("In getJobStatus, jobname not recognized " + jobname)
        return
      }
      if (pid !== state.currentPID) {
        ConsoleHelper("Error job in wrong project [" + jid, "] - " + pid)
        return
      }
      if (jobname === UPLOAD_JOB) {
        toast.error("Failed uploading job. Removing image: " + iid)
        delete state.currentProject.images[iid]
      } else {
        // toast.error("Failed " + jobname + " job")
        if (state.currentProject.images[iid] !== undefined) {
          if (state.currentProject.images[iid].jobs[jobname] === undefined)
            state.currentProject.images[iid].jobs[jobname] = {}
          state.currentProject.images[iid].jobs[jobname].status = STATUS_FAILED
          state.currentProject.images[iid].jobs[jobname].progress = 0
        }
      }
    }
  }
})

export const {
  setCurrentPID,
  doJobSuccess,
  killJobSuccess,
  getJobStatusSuccess,
  getProjectsSuccess,
  getCurrentProjectSuccess,
  setCurrentProject,
  uploadProjectSuccess,
  deleteProjectSuccess,
  addWorkflowSuccess,
  deleteWorkflowSuccess,
  deleteExpSuccess,
  failedJob,
  setUploadSource,
  setUploadID,
  updateProjectLikesSuccess
} = projectSlice.actions

export const doJob = (payload) => (dispatch) => {
  // ConsoleHelper("Submiting job" , payload.data)
  const pid = payload.pid
  const iid = payload.iid
  const jobname = payload.jobname
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + pid + "/" + iid + "/" + jobname,
      payload.data
    )
    .then((res) => {
      const data = { ...res.data, ...payload }
      dispatch(doJobSuccess(data))
      // ConsoleHelper("Send Job correctly " + res.data)
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.data) {
          toast.error(e.response.data.error)
          ConsoleHelper("Error Message " + e.response.data.error)
        } else ConsoleHelper("Error " + e.response.status, " Message: " + e.response.statusText)
      }
      dispatch(failedJob(payload))
    })
}

export const killJob = (payload) => (dispatch) => {
  ConsoleHelper("Killing job" + payload)
  const pid = payload.pid
  const iid = payload.iid
  const jid = payload.jid
  const jobname = payload.jobname
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        pid +
        "/" +
        iid +
        "/kill/" +
        jobname +
        "?id=" +
        jid,
      payload.data
    )
    .then((res) => {
      const data = { ...res.data, ...payload }
      // ConsoleHelper("Kill jobSend Job correctly " + res.data)
      dispatch(killJobSuccess(data))
    })
    .catch(() => {
      ConsoleHelper("Not able to kill job ")
      dispatch(killJobSuccess(payload))
    })
}

export const deleteJob = (payload = {}) => (dispatch) => {
  ConsoleHelper("DeleteJob job", payload)
  const { iid, pid, jobname, jid } = payload
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        pid + 
        "/" +
        iid +
        "/delete/" +
        jobname +
        "?id=" +
        jid,
        payload.data
    )
    .then((res) => {
      dispatch(setCurrentProject(res.data))
    })
    .catch(() => {
      ConsoleHelper("Error deleteJob")
    })
}

export const getJobStatus = (payload) => (dispatch) => {
  // ConsoleHelper("Getting Job status" + payload)
  const pid = payload.pid
  const iid = payload.iid
  const jid = payload.jid
  const jobname = payload.jobname
  const data = isAuth()
  if (data.email !== undefined) {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + pid + "/" + iid + "/" + jobname + "?id=" + jid
      )
      .then((res) => {
        const data = { ...res.data, ...payload }
        if (res?.data && res?.data.iid !== payload.iid) data.real_iid = res.data.iid
        dispatch(getJobStatusSuccess(data))
      })
      .catch((error) => {
        ConsoleHelper("Error getJobStatus" + error)
        dispatch(failedJob(payload))
      })
  }
}

export const getProjects = (payload) => (dispatch) => {
  //ConsoleHelper("Getting Projects", payload)
  if (!isGettingProjects) {
    isGettingProjects = true
    let pattern = {}
    if (payload !== undefined) {
      pattern = payload
    }
    pattern = JSON.stringify(pattern)
    const token = getCookie("token")
    const data = isAuth()
    axios
      .get(process.env.REACT_APP_API_URL + "/backend/" + data.email + "/all/" + pattern, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        dispatch(getProjectsSuccess(res.data))
        isGettingProjects = false
      })
      .catch((error) => {
        ConsoleHelper("Error getProjects" + error)
        isGettingProjects = false
      })
  }
}

export const getProject = (pid) => (dispatch) => {
  // ConsoleHelper("Getting Project " + pid)
  const token = getCookie("token")
  const data = isAuth()
  axios
    .get(process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + pid, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch(getCurrentProjectSuccess(res.data))
    })
    .catch((error) => {
      ConsoleHelper("Error getProject" + error)
    })
}

export const getProjectAsync = (pid) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // ConsoleHelper("Getting Project " + pid)
    try {
      const token = getCookie("token")
      const data = isAuth()
      axios
        .get(process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + pid, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          dispatch(getCurrentProjectSuccess(res.data))
          resolve(true)
        })
        .catch((error) => {
          ConsoleHelper("Error getProject" + error)
          resolve(false)
        })
    } catch (e) {
      reject(e)
    }
  })
}

export const updateCurrentProject = (pid) => (dispatch) => {
  // ConsoleHelper("Updating Current Project " + pid)
  dispatch(setCurrentPID(pid))
  dispatch(getProject(pid))
}

export const updateProjectLikes = (pid) => (dispatch) => {
  const token = getCookie("token")
  const data = isAuth()
  axios
    .put(process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + pid + "/like", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch(updateProjectLikesSuccess(res.data))
    })
    .catch((error) => {
      ConsoleHelper("Error getProjects" + error)
    })
}

export const uploadImage = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // ConsoleHelper("Sending Image" + payload)
    let data = {
      pid: payload.pid,
      iid: payload.iid,
      jid: 0,
      jobname: UPLOAD_JOB,
      status: STATUS_RUNNING,
      newImage: payload.newImage,
      doJob: payload.jobname
    }

    dispatch(doJobSuccess(data))
    dispatch(setUploadID(payload.iid))
    const email = isAuth().email
    uploadSource = axios.CancelToken.source()

    axios
      .post(
        process.env.REACT_APP_API_URL + "/backend/" + email + "/" + payload.pid + "/upload/" + payload.iid,
        payload.data,
        {
          onUploadProgress: (ProgressEvent) => {
            let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100, 2)
            // ConsoleHelper("Upload progress: " + progress)
            data = { ...data, progress }
            dispatch(getJobStatusSuccess(data))
          },
          cancelToken: uploadSource.token
        }
      )
      .then((res) => {
        // then print response status
        ConsoleHelper("Uploading Success getting project", res.data)
        dispatch(getProject(payload.pid))
        if (res.data.jobname === EXTRA_JOB) dispatch(doJobSuccess(res.data))
        data = { ...data, progress: 0, status: STATUS_FINISHED }
        dispatch(getJobStatusSuccess(data))
        payload = null
        uploadSource = undefined
        resolve(res.data)
      })
      .catch((e) => {
        ConsoleHelper("Error in Upload ", e)
        if (e.response) {
          if (e.response.data) {
            toast.error(e.response.data.error)
            ConsoleHelper("Error Message " + e.response.data.error)
          } else ConsoleHelper("Error " + e.response.status, " Message: " + e.response.statusText)
        } else {
          ConsoleHelper("Error Message " + e.message)
        }
        dispatch(failedJob(data))
        payload = null
        uploadSource = undefined
        reject()
      })
  })
}

export const cancelUpload = () => (dispatch) => {
  ConsoleHelper("Cancelling upload")
  if (uploadSource !== undefined) {
    uploadSource.cancel("Cancel by user")
    dispatch(setUploadID(undefined))
    uploadSource = undefined
  }
}

export const deleteImage = (payload) => (dispatch) => {
  ConsoleHelper("Removing Image", payload)
  const data = isAuth()
  axios
    .get(
      process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + payload.pid + "/delete/" + payload.iid,
      payload
    )
    .then((res) => {
      // then print response status
      ConsoleHelper("Success from removing ", payload)
      dispatch(getCurrentProjectSuccess(res.data))
    })
    .catch((err) => {
      ConsoleHelper("Error removing Image" + err)
    })
}

export const updateImage = (payload) => (dispatch) => {
  ConsoleHelper("Updating Image", payload)
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL + "/backend/" + data.email + "/" + payload.pid + "/update/" + payload.iid,
      payload
    )
    .then(() => {
      // then print response status
      ConsoleHelper("Success updating image ", payload)
      dispatch(getProject(payload.pid))
    })
    .catch((err) => {
      ConsoleHelper("Error updating Image" + err)
    })
}

export const createFolder = (payload) => (dispatch) => {
  ConsoleHelper("Creating Folder", payload)
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        payload.pid +
        "/createfolder/" +
        payload.iidParent,
      payload
    )
    .then(() => {
      ConsoleHelper("Success creating folder ", payload)
      dispatch(getProject(payload.pid))
    })
    .catch((err) => {
      ConsoleHelper("Error updating Image" + err)
    })
}

export const uploadProject = (payload) => (dispatch) => {
  // ConsoleHelper("Creating Project" + payload.data.get('name'))
  const data = isAuth()
  axios
    .post(process.env.REACT_APP_API_URL + "/backend/" + data.email + "/upload", payload.data)
    .then((res) => {
      dispatch(uploadProjectSuccess(res.data))
      dispatch(updateCurrentProject(res.data.pid))
    })
    .catch((err) => {
      ConsoleHelper("Error creating Project " + err)
    })
}

export const deleteProject = (payload) => (dispatch) => {
  // ConsoleHelper("Removing Project" + payload.id)
  const data = isAuth()
  axios
    .post(process.env.REACT_APP_API_URL + "/backend/" + data.email + "/delete", payload)
    .then((res) => {
      dispatch(deleteProjectSuccess(res.data))
    })
    .catch((err) => {
      ConsoleHelper("Error removing Project" + err)
    })
}

export const addWorkflow = (payload) => (dispatch) => {
  // We should add the workflow to the server
  dispatch(addWorkflowSuccess(payload))
}

export const deleteWorkflow = (payload) => (dispatch) => {
  // We should add the workflow to the server
  dispatch(deleteWorkflowSuccess(payload))
}

export const deleteExp = (payload) => (dispatch) => {
  // We should add the workflow to the server
  dispatch(deleteExpSuccess(payload))
}

export default projectSlice.reducer
