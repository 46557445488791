import cookie from "js-cookie"

// Set in Cookie
export const setCookie = (key, value) => {
  if (window !== "undefined") {
    cookie.set(key, value, {
      // 1 Day
      expires: 7
    })
  }
}
// remove from cookie
export const removeCookie = (key) => {
  if (window !== "undefined") {
    cookie.remove(key, {
      expires: 1
    })
  }
}

// Get from cookie such as stored token
// Will be useful when we need to make request to server with token
export const getCookie = (key) => {
  if (window !== "undefined") {
    return cookie.get(key)
  }
}

// Set in localstorage
export const setLocalStorage = (key, value) => {
  if (window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

// get from localstorage
export const getLocalStorage = (key, defaultValue) => {
  if (window !== "undefined") {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key)
    }
    else{
      return defaultValue
    }
  }
  else{
    return defaultValue
  }
}

// Remove from localstorage
export const removeLocalStorage = (key) => {
  if (window !== "undefined") {
    localStorage.removeItem(key)
  }
}

// Authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (response, next) => {
  //ConsoleHelper('AUTHENTICATE HELPER ON SIGNIN RESPONSE', response)
  setCookie("token", response.data.token)
  setLocalStorage("user", response.data.user)
  next()
}

// Access user info from localstorage
export const isAuth = () => {
  if (window !== "undefined") {
    const cookieChecked = getCookie("token")
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        // ConsoleHelper("In Authorized ", localStorage.getItem('user'))
        let data = JSON.parse(localStorage.getItem("user"))
        data.nickName = data.email.match(/^([^@]*)@/)[1]
        return data
      } else {
        return false
      }
    }
  }
  return false
}

export const signout = (next) => {
  removeCookie("token")
  removeLocalStorage("user")
  next()
}

export const updateUser = (response, next) => {
  ConsoleHelper("UPDATE USER IN LOCALSTORAGE HELPERS", response)
  if (typeof window !== "undefined") {
    let auth = response.data
    localStorage.setItem("user", JSON.stringify(auth))
  }
  next()
}
