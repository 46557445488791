
import { useCallback ,useState} from "react"

function useMicroPorosity({defaultValue}) {
  const [microPorosity, setMicroPorosity] = useState(defaultValue||0.5)

  const updateMicroPorosity = useCallback((value) => {
    setMicroPorosity(value)
  },[])
  

  return {
    microPorosity,
    updateMicroPorosity
  }
}

export default useMicroPorosity

