import "./Settings.css"

import axios from "axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { getCookie, isAuth, signout, updateUser } from "../../helpers/auth"
import ConsoleHelper from "../../helpers/ConsoleHelper"

const Settings = ({ history }) => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    company: "",
    textChange: "Update",
    role: ""
  })

  useEffect(() => {
    const loadProfile = () => {
      const token = getCookie("token")
      axios
        .get(`${process.env.REACT_APP_API_URL}/backend/user/${isAuth()._id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          ConsoleHelper("New Settings ", res.data)
          const { role, name, email, company, cpuTime, gpuTime } = res.data
          setFormData((prevForm) => ({
            ...prevForm,
            role,
            name,
            email,
            company,
            cpuTime,
            gpuTime
          }))
        })
        .catch((err) => {
          toast.error(`Error To Your Information ${err.response.statusText}`)
          if (err.response.status === 401) {
            signout(() => {
              history.push("/login")
            })
          }
        })
    }
    loadProfile()
  }, [history])

  const { name, email, textChange, company, cpuTime, gpuTime } = formData
  const handleChange = (text) => (e) => {
    let value = e.target.value
    if (text === "company") value = value.charAt(0).toUpperCase() + value.slice(1)
    if (text === "email") value = value.toLowerCase()
    setFormData((prevForm) => ({ ...prevForm, [text]: value }))
  }

  const handleSubmit = (e) => {
    const token = getCookie("token")
    e.preventDefault()
    setFormData((prevForm) => ({ ...prevForm, textChange: "Submitting" }))
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/backend/user/update`,
        {
          email,
          name,
          company
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        updateUser(res, () => {
          toast.success("Profile Updated Successfully")
          setFormData((prevForm) => ({ ...prevForm, textChange: "Update" }))
        })
      })
      .catch((err) => {
        if (err.response.data.errors) toast.error(err.response.data.errors)
        else if (err.response) {
          toast.error("Error connecting to Server - " + err.response.status + " " + err.response.statusText)
        } else {
          toast.error("Error: No connection to server. Contact support")
        }
      })
  }

  const handleCalc = (value) => {
    if (!value) {
      return 0
    }
    return Number(value.toFixed(1))
  }

  return (
    <div className="min-h-full pt-4 text-gray-900 flex justify-center">
      <div
        className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow 
        sm:rounded-lg flex justify-center flex-1"
      >
        <div className="mt-4 flex flex-col items-center">
          <div className="w-full flex-1 mt-2 text-indigo-500">
            <h2 className="text-2xl xl:text-3xl font-extrabold text-3BC">User Settings</h2>

            <form className="w-full flex-1 mt-3 text-indigo-500" onSubmit={handleSubmit}>
              <div className="mx-auto max-w-xs relative ">
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white mt-2 text-3BC"
                  onChange={handleChange("name")}
                  placeholder="Name"
                  type="text"
                  value={name}
                />
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white mt-2 text-3BC"
                  onChange={handleChange("company")}
                  placeholder="Company"
                  type="text"
                  value={company}
                />
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white mt-2 text-3BC"
                  disabled
                  placeholder="Email"
                  type="email"
                  value={email}
                />
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white mt-2 text-3BC"
                  disabled
                  placeholder="CPU Time"
                  type="text"
                  value={`${handleCalc(cpuTime)} hrs CPU Time`}
                />
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white mt-2 text-3BC"
                  disabled
                  placeholder="GPU Time"
                  type="text"
                  value={`${handleCalc(gpuTime)} hrs GPU Time`}
                />

                <button
                  className="mt-2 tracking-wide font-semibold bg-indigo-500 text-gray-100 
                    w-full py-3 rounded-lg hover:bg-indigo-700 transition-all duration-300 
                    ease-in-out flex items-center justify-center focus:shadow-outline 
                    focus:outline-none btn-update"
                  type="submit"
                >
                  <i className="fas fa-user-plus fa 1x w-6  -ml-2" />
                  <span className="ml-3">{textChange}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
