import "./MultiInputsProcess.css"

import React, { useContext, useEffect, useMemo, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { ArrowRightCircle, Edit, X } from "react-feather"
import { useDispatch } from "react-redux"
import { Button, Progress } from "reactstrap"
import Rodal from "rodal"

import { addImagesToMIWorkflow, addToolToMIWorkflow } from "../../actions/workflowSlice"
import JobIcon from "../../components/JobIcon"
import CropParameters from "../../components/Parameters/CropParameters"
import NetexParameters from "../../components/Parameters/NetexParameters"
import NetsimParameters from "../../components/Parameters/NetsimParameters"
// import SuperResolutionParameter from "../../components/Parameters/SuperResolutionParameter"
import SegParameters from "../../components/Parameters/SegParameters"
import SuperResolutionParameter from "../../components/Parameters/SupperResolutionParameter"
import {
  CROP_JOB,
  NETSIM_JOB,
  NETWORK_JOB,
  SEGMENTATION_JOB,
  STATUS_FINISHED,
  SUPERRESOLUTION_JOB
} from "../../constants/jobnames"
import { WorkflowContext, WorkflowContextProvider } from "../../helpers/WorkflowContext"
import { getCropData, getDefaultCropValues } from "../../utils/images"

const allTools = [CROP_JOB, SUPERRESOLUTION_JOB, SEGMENTATION_JOB, NETWORK_JOB, NETSIM_JOB]

const toolNameMapping = {
  [CROP_JOB]: "Crop",
  [SUPERRESOLUTION_JOB]: "SR",
  [SEGMENTATION_JOB]: "Seg",
  [NETWORK_JOB]: "Netex",
  [NETSIM_JOB]: "Netsim"
}

const parameterNameMapping = {
  [CROP_JOB]: {
    x: "Origin X",
    y: "Origin Y",
    z: "Origin Z",
    cx: "Crop Size X",
    cy: "Crop Size Y",
    cz: "Crop Size Z"
  },
  [SUPERRESOLUTION_JOB]: {
    scale: "Scale",
    model: "Model"
  },
  [SEGMENTATION_JOB]: {
    model: "Model",
    thresholdMin: "Min Norm",
    thresholdMax: "Max Norm",
    adjust: "Interactive Adjustment",
    adjustValues: ["Pore", "Pore", "Micro"]
  },
  [NETWORK_JOB]: {
    microPorosity: "Micro-Phase Porosity"
  },
  [NETSIM_JOB]: {
    doPD: "Primary Drainage (PD)",
    doIMB: "Imbibition",
    scanning: "Scanning",
    direction: "Injection Direction",
    stopSwPD: "Min Saturation in PD",
    sow: "Interfacial Tension",
    densityW: "Brine Density",
    densityO: "Oil Density",
    oilWetFraction: "Oil-Wet Fraction",
    advancingWaterMin: "Min Adv. Angle Water-Wet",
    advancingWaterMax: "Max Adv. Angle Water-Wet",
    advancingOilMin: "Min Adv. Angle Oil-Wet",
    advancingOilMax: "Max Adv. Angle Oil-Wet",
    distribution: "Distribution"
  }
}

const metaToolsDefault = {
  [CROP_JOB]: {
    x: 20,
    y: 20,
    z: 152,
    cx: 160,
    cy: 160,
    cz: 496
  },
  [SUPERRESOLUTION_JOB]: {
    scale: 2,
    model: "Stable_v1.0"
  },
  [SEGMENTATION_JOB]: {
    model: "StableST_v1.0",
    thresholdMin: 0.02,
    thresholdMax: 0.04,
    adjust: false,
    adjustValues: [0, 0, 0],
    confimap: false
  },
  [NETWORK_JOB]: {
    microPorosity: 0.2
  },
  [NETSIM_JOB]: {
    properties: {
      doPD: true,
      doIMB: true,
      scanning: false,
      direction: "X",
      stopSwPD: 0,
      sow: 0.03,
      densityW: 1001,
      densityO: 401,
      oilWetFraction: 0,
      advancingWaterMin: 30,
      advancingWaterMax: 60,
      advancingOilMin: 120,
      advancingOilMax: 150,
      distribution: 1
    }
  }
}

const MultiInputsProcess = ({ isPlaying, images, workflowInfo }) => {
  const dispatch = useDispatch()

  const [imagesUnselected, setImagesUnselected] = useState([])
  const [imagesSelected, setImagesSelected] = useState([])
  const [metaTools, setMetaTools] = useState({})
  const [toolSelected, setToolSelected] = useState([])

  const projectImagesString = useMemo(() => JSON.stringify(images || []), [images])
  const inputImagesWorkflowString = useMemo(
    () => JSON.stringify(workflowInfo?.inputImages || []),
    [workflowInfo?.inputImages]
  )
  const metaToolString = useMemo(() => JSON.stringify(workflowInfo?.metaTools || {}), [workflowInfo?.metaTools])

  useEffect(() => {
    const inputImages = JSON.parse(inputImagesWorkflowString)
    const projectImages = JSON.parse(projectImagesString)
    const metaTools = JSON.parse(metaToolString)

    // Reset Selected/Unselect images
    let newImageSelected = []
    let newImageUnselected = []
    projectImages.forEach((_image) => {
      if (inputImages.includes(_image.iid)) return newImageSelected.push(_image)
      newImageUnselected.push(_image)
    })
    setImagesSelected(newImageSelected)
    setImagesUnselected(newImageUnselected)

    // Reset meta tool
    const newToolSelected = Object.keys(metaTools)
    const newMetaTools = {}
    newToolSelected.forEach((toolName) => {
      newMetaTools[toolName] = metaTools[toolName]?.meta || null
    })
    setToolSelected(newToolSelected)
    setMetaTools(newMetaTools)
  }, [inputImagesWorkflowString, projectImagesString, metaToolString])

  const handleSelectImageToggle = (iid) => {
    if (isPlaying) return

    const imageIndex = imagesUnselected.findIndex((e) => e.iid === iid)
    const imageSelectedIndex = imagesSelected.findIndex((e) => e.iid === iid)
    if (imageIndex !== -1) {
      // Add image
      const newImagesSelected = [...imagesSelected, imagesUnselected[imageIndex]]
      imagesUnselected.splice(imageIndex, 1)
      setImagesUnselected(imagesUnselected)
      setImagesSelected(newImagesSelected)

      const payload = {
        wid: workflowInfo?._id,
        projectId: workflowInfo?.pid,
        inputImages: newImagesSelected?.map((e) => e.iid)
      }
      dispatch(addImagesToMIWorkflow(payload))
    }
    if (imageSelectedIndex !== -1) {
      // Remove image
      setImagesUnselected([...imagesUnselected, imagesSelected[imageSelectedIndex]])
      imagesSelected.splice(imageSelectedIndex, 1)
      setImagesSelected(imagesSelected)
    }
  }

  const handleRemoveTool = (typeTool) => {
    const indexExist = toolSelected.findIndex((_tool) => _tool === typeTool)
    if (indexExist === -1) return
    const temp = [...toolSelected]
    temp.splice(indexExist, 1)
    setToolSelected(temp)
  }

  const handleAddTool = (typeTool) => {
    const indexExist = toolSelected.findIndex((_tool) => _tool === typeTool)
    if (indexExist !== -1) return

    const _meta = metaToolsDefault[typeTool]

    const payload = {
      projectId: workflowInfo?.pid,
      wid: workflowInfo._id,
      keyTool: typeTool,
      meta: _meta
    }
    dispatch(addToolToMIWorkflow(payload))
    setToolSelected([...toolSelected, typeTool])
  }

  const outputWorkflow = workflowInfo.outputs || {}

  return (
    <WorkflowContextProvider>
      <div className="multiInputsProcess">
        {!isPlaying && (
          <div className="listImageWrapper">
            <div className="titleCol"> Images </div>
            <div className="listImage">
              {imagesUnselected.map((e) => (
                <p className="imageItem" key={e.iid} onClick={() => handleSelectImageToggle(e.iid)}>
                  {e.filename}
                  <ArrowRightCircle active={"t"} size={18} />
                </p>
              ))}
            </div>
          </div>
        )}

        <div className="processTableWrapper">
          <div className="multiInputsProcessTable">
            <div className="thead_multiInputsProcessTable layout_multiInputsProcessTable">
              <div className="td_multiInputsProcessTable titleCol stickyLeft"> Images Selected</div>
              {allTools.map((typeTool, i) => (
                <div className="td_multiInputsProcessTable titleCol" key={i}>
                  {toolNameMapping[typeTool]}

                  {!isPlaying && (
                    <>
                      {toolSelected.includes(typeTool) ? (
                        <Button
                          className="btn-danger add-workflow-button small"
                          onClick={() => handleRemoveTool(typeTool)}
                        >
                          <i className="fa fa-minus"></i>
                        </Button>
                      ) : (
                        <Button
                          className="btn-primary add-workflow-button small"
                          onClick={() => handleAddTool(typeTool)}
                        >
                          <i className="fa fa-plus fa-fw" />
                        </Button>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>

            <div className="tbody_multiInputsProcessTable">
              <div className="tr_multiInputsProcessTable layout_multiInputsProcessTable">
                <div className="td_multiInputsProcessTable stickyLeft"></div>
                {allTools.map((typeTool, i) => {
                  if (toolSelected.includes(typeTool)) {
                    let _metaTool = metaTools[typeTool] || metaToolsDefault[typeTool]
                    if (typeTool === NETSIM_JOB) {
                      _metaTool = _metaTool?.properties ?? {}
                    }
                    return (
                      <ToolParameter
                        isPlaying={isPlaying}
                        key={typeTool + i}
                        onChange={(newParameter) => {
                          const payload = {
                            keyTool: typeTool,
                            meta: newParameter[typeTool],
                            projectId: workflowInfo?.pid,
                            wid: workflowInfo._id
                          }

                          setMetaTools({ ...metaTools, ...newParameter })
                          dispatch(addToolToMIWorkflow(payload))
                        }}
                        parameters={_metaTool}
                        type={typeTool}
                      />
                    )
                  }

                  return <div key={i}></div>
                })}
              </div>

              {imagesSelected.map((_image) => {
                const totalTool = Object.keys(workflowInfo?.metaTools)
                const jobToolOutput = Object.values(outputWorkflow?.[_image?.iid] || {})
                const jobTool = workflowInfo.jobs?.[_image?.iid] || {}
                const jobFinished = jobToolOutput.filter((e) => e?.status === STATUS_FINISHED).length
                const imageProcess = (jobFinished / totalTool.length) * 100 || 0

                return (
                  <div className="tr_multiInputsProcessTable layout_multiInputsProcessTable" key={_image.iid}>
                    <div className="td_multiInputsProcessTable stickyLeft">
                      <Progress className="mt-0 w-100 mb-3" color="success" max="100" value={imageProcess}>
                        {imageProcess}%
                      </Progress>
                      <p className="imageItem" onClick={() => handleSelectImageToggle(_image.iid)}>
                        <X active={"t"} hidden={isPlaying} size={18} />
                        {_image.filename}
                        <JobIcon status={jobTool?.status} />
                      </p>
                    </div>
                    {allTools.map((typeTool, i) => {
                      if (toolSelected.includes(typeTool)) {
                        const toolProcess = workflowInfo?.jobs?.[_image?.iid]?.tools?.[typeTool]?.progress || 0
                        const outputTool = outputWorkflow?.[_image?.iid]?.[typeTool]
                        return (
                          <ToolInfo
                            isPlaying={isPlaying}
                            key={typeTool + i}
                            output={outputTool}
                            progress={toolProcess}
                            type={typeTool}
                          />
                        )
                      }

                      return <div key={i}></div>
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </WorkflowContextProvider>
  )
}

const ToolParameter = ({ isPlaying, type, parameters, onChange }) => {
  const [isOpen, setOpen] = useState(false)

  const ToolParameterItem = ({ name, value }) => {
    return (
      <div className="parameterToolsItem">
        <p className="toolName">{name || ""}:</p>
        <p className="toolValue">{value?.toString() || ""}</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="td_multiInputsProcessTable">
        <div className="d-flex justify-content-between align-items-center">
          <p className="txtSubtitle">Parameters:</p>
          {!isPlaying && (
            <Button
              className="btn-primary"
              onClick={() => {
                setOpen(true)
              }}
              size="sm"
            >
              <Edit size={12} />
            </Button>
          )}
        </div>
        <div className="parameterTools">
          {Object.keys(parameters).map((field, i) => (
            <ToolParameterItem
              key={i}
              name={parameterNameMapping?.[type]?.[field] || field || ""}
              value={parameters[field]}
            />
          ))}
        </div>
      </div>

      <ParameterModal
        defaultParameter={parameters}
        isOpen={isOpen}
        onChange={(data) => onChange(data)}
        onCloseModal={() => setOpen(false)}
        type={type}
      />
    </React.Fragment>
  )
}

const ParameterModal = ({ isOpen, defaultParameter, onCloseModal, type, onChange }) => {
  const { metaImage, handleSetImageMeta } = useContext(WorkflowContext)
  const [parameters, setParameters] = useState({})

  useEffect(() => {
    let _parameters = {}
    switch (type) {
      case CROP_JOB:
        _parameters = getDefaultCropValues(metaImage)
        break
      case SUPERRESOLUTION_JOB:
        _parameters = defaultParameter || {}
        break
      case SEGMENTATION_JOB:
        _parameters = defaultParameter || {}
        break
      case NETWORK_JOB:
        _parameters = defaultParameter || {}
        break
      case NETSIM_JOB:
        _parameters = defaultParameter || metaToolsDefault.netsim.properties
        break
      default:
        break
    }

    setParameters(_parameters)
  }, [metaImage, type, defaultParameter])

  const handleUpdateImageSetting = (newParameter = {}) => {
    if (!newParameter || !Object.keys(newParameter).length) return

    handleSetImageMeta({ ...metaImage, ...newParameter })
  }

  const onSubmitModal = () => {
    switch (type) {
      case CROP_JOB:
        onChange({ [CROP_JOB]: getCropData(metaImage, parameters) })
        break
      case SUPERRESOLUTION_JOB:
        onChange({ [SUPERRESOLUTION_JOB]: parameters })
        break
      case SEGMENTATION_JOB:
        onChange({
          [SEGMENTATION_JOB]: {
            ...parameters,
            adjust: false,
            adjustValues: [0, 0, 0],
            confimap: false
          }
        })
        break
      case NETWORK_JOB:
        onChange({ [NETWORK_JOB]: parameters })
        break
      case NETSIM_JOB:
        onChange({ [NETSIM_JOB]: { properties: parameters } })
        break
      default:
        break
    }

    onCloseModal()
  }

  if (!isOpen) return <></>

  return (
    <Rodal customStyles={{ height: "auto", bottom: "auto", top: "30%" }} onClose={onCloseModal} visible={isOpen}>
      <h1 className="title"> {toolNameMapping[type]} parameter </h1>

      {type === CROP_JOB && (
        <React.Fragment>
          <p className="titleSettingImage">Setting Image</p>
          <div className="settingImageOptions">
            <Row className="row-parameter">
              <Col xs={8}>Nx</Col>
              <Col xs={4}>
                <input
                  className="number-parameter w-100"
                  onChange={(e) => handleUpdateImageSetting({ nx: e.target.value })}
                  value={metaImage?.nx}
                />
              </Col>
            </Row>
            <Row className="row-parameter">
              <Col xs={8}>Ny</Col>
              <Col xs={4}>
                <input
                  className="number-parameter w-100"
                  onChange={(e) => handleUpdateImageSetting({ ny: e.target.value })}
                  value={metaImage?.ny}
                />
              </Col>
            </Row>
            <Row className="row-parameter">
              <Col xs={8}>Nz</Col>
              <Col xs={4}>
                <input
                  className="number-parameter w-100"
                  onChange={(e) => handleUpdateImageSetting({ nz: e.target.value })}
                  value={metaImage?.nz}
                />
              </Col>
            </Row>
          </div>
          <CropParameters
            image={metaImage}
            onChange={(newParameter) => setParameters(newParameter)}
            openAdvancedDefault={true}
            parameter={parameters}
          />
        </React.Fragment>
      )}
      {type === SUPERRESOLUTION_JOB && (
        <SuperResolutionParameter
          onChange={(newParameter) => setParameters({ ...parameters, ...newParameter })}
          parameter={parameters}
        />
      )}
      {type === SEGMENTATION_JOB && (
        <SegParameters
          onChange={(newParameter) => setParameters({ ...parameters, ...newParameter })}
          openAdvancedDefault={true}
          parameter={parameters}
        />
      )}

      {type === NETWORK_JOB && (
        <NetexParameters
          image={metaImage}
          onChange={(newParameter) => setParameters(newParameter)}
          openAdvancedDefault={true}
          parameter={parameters}
        />
      )}

      {type === NETSIM_JOB && (
        <NetsimParameters
          image={metaImage}
          onChange={(newParameter) => setParameters(newParameter)}
          openAdvancedDefault={true}
          parameter={parameters}
        />
      )}
      <div className="button-holder flex gap-8 justify-center">
        <Button className="button" onClick={onSubmitModal} variant="primary">
          {" "}
          OK{" "}
        </Button>
        <Button className="button" onClick={onCloseModal} variant="secondary">
          {" "}
          Cancel{" "}
        </Button>
      </div>
    </Rodal>
  )
}

const ToolInfo = React.memo(({ output, progress, isPlaying }) => {
  return (
    <React.Fragment>
      <div className="td_multiInputsProcessTable">
        <Progress className="mt-0 w-100 mb-2" color="success" hidden={!isPlaying} max="100" value={progress}>
          {progress}%
        </Progress>
        {output?.info?.filename && (
          <div className="processResultWrapper">
            <p className="txtSubtitle">Result</p>
            <div className="processResult">
              <p className="outputName">{output?.info?.filename}</p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
})

export default MultiInputsProcess
