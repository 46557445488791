import React from "react"
import { Navigate, useLocation } from "react-router-dom"

import { isAuth } from "../helpers/auth"

const PrivateRoute = ({ children }) => {
  let auth = isAuth()
  let location = useLocation()
  // console.log("Auth in PrivateRoute: ", auth)

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate state={{ from: location }} to="/login" />
  }

  return children
}

export default PrivateRoute
