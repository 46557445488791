import './Parameters.css'

import React from 'react'

import { ExpandableList } from '../ExpandableList'

const SIZE_DEFAULT = 0
const CropParameters = ({ image = null, parameter, onChange, openAdvancedDefault }) => {

    console.log('image: ', image)
    const crop = parameter
    console.log('crop in CropParameters ', crop)

    return (
        <ExpandableList
            openDefault={openAdvancedDefault}
            ariaLabel="advanced crop options"
            label={"Advanced Options"}
            list={[
            {
                label: "Origin X",
                value: Math.ceil((crop.x / 100) * (image?.nx || SIZE_DEFAULT)),
                // value: Math.ceil((crop.x / 100)),
                min: 0,
                max: 100,
                onChange: (x) => onChange({ ...crop, x : (x / (image?.nx || SIZE_DEFAULT)) * 100 })
                // onChange: (x) => onChange({ ...crop, x : (x / (image?.nx || SIZE_DEFAULT)) * 100 })
            },
            {
                label: "Origin Y",
                value: Math.ceil((crop.y / 100) * (image?.nx || SIZE_DEFAULT)),
                min: 0,
                max: 100 * (image?.ny || SIZE_DEFAULT),
                onChange: (y) => onChange({ ...crop, y : (y / (image?.ny || SIZE_DEFAULT)) * 100 })
            },
            {
                label: "Origin Z",
                value: Math.ceil((crop.z / 100) * (image?.nz || SIZE_DEFAULT)),
                min: 0,
                max: 100 * (image?.nz || SIZE_DEFAULT),
                onChange: (z) => onChange({ ...crop, z : ((z / (image?.nz || SIZE_DEFAULT)) * 100) })
            },
            {
                label: `Crop Size X`,
                value: Math.ceil((image?.nx || SIZE_DEFAULT) * (crop.cx / 100)),
                min: 0,
                max: 100 * (image?.nx || SIZE_DEFAULT),
                onChange: (cx) => onChange({ ...crop, cx : ((cx / (image?.nx || SIZE_DEFAULT)) * 100) })
            },
            {
                label: `Crop Size Y`,
                value: Math.ceil((image?.ny || SIZE_DEFAULT) * (crop.cy / 100)),
                min: 0,
                max: 100 * (image?.ny || SIZE_DEFAULT),
                onChange: (cy) => onChange({ ...crop, cy : ((cy / (image?.ny || SIZE_DEFAULT)) * 100) })
            },
            {
                label: `Crop Size Z`,
                value: Math.ceil((image?.nz || SIZE_DEFAULT) * (crop.cz / 100)),
                min: 0,
                max: 100 * (image?.nz || SIZE_DEFAULT),
                onChange: (cz) => onChange({ ...crop, cz : ((cz / (image?.nz || SIZE_DEFAULT)) * 100) })
            }
            ]}
        />
    )
}

export default CropParameters