import React, { memo } from "react"

import { isAuth } from "../../helpers/auth"
import ImageFrame from "../ImageFrame"

const JOB_PREFIX = "gen_"

const OutputFrame = ({ job, cutPlane, pid, viewID, jobType, zOption, updateCutPlane }) => {
  let imageID = viewID
  const user = isAuth().email
  return (
    <ImageFrame
      cutPlane={cutPlane}
      disableSlider={true}
      job={job}
      title="Output"
      updatePlane={(value) => updateCutPlane(value)}
      updateZoomEvent={() => {}}
      xyImageName={
        process.env.REACT_APP_API_URL +
        "/backend/" +
        user +
        "/" +
        pid +
        "/" +
        imageID +
        "/" +
        jobType +
        "/" +
        JOB_PREFIX +
        "XY.png"
      }
      xzImageName={
        process.env.REACT_APP_API_URL +
        "/backend/" +
        user +
        "/" +
        pid +
        "/" +
        imageID +
        "/" +
        jobType +
        "/" +
        JOB_PREFIX +
        "XZ.png"
      }
      yzImageName={
        process.env.REACT_APP_API_URL +
        "/backend/" +
        user +
        "/" +
        pid +
        "/" +
        imageID +
        "/" +
        jobType +
        "/" +
        JOB_PREFIX +
        "YZ.png"
      }
      zoption={zOption}
    />
  )
}
export default memo(OutputFrame)
