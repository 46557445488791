import React, { memo } from "react"

import { SEGMENTATION_JOB, STATUS_FINISHED } from "../../constants/jobnames"
import ImageFrame from "../ImageFrame"

const PrintImages = ({ job, user, cutPlane, zOption, updateCutPlane, updateZoom, viewID, jobType, pid, prefix }) => {
  if (job.status === STATUS_FINISHED) {
    const basename = process.env.REACT_APP_API_URL + "/backend/" + user + "/" + pid + "/" + viewID + "/" + jobType
    const jid = job.jid

    return (
      <div className="images-container">
        <ImageFrame
          cutPlane={cutPlane}
          job={job}
          title="Input"
          updatePlane={(value) => updateCutPlane(value)}
          updateZoomEvent={(value) => updateZoom(value)}
          xyImageName={basename + "/tomo_XY.png"}
          xzImageName={basename + "/tomo_XZ.png"}
          yzImageName={basename + "/tomo_YZ.png"}
          zoption={zOption}
        />
        <ImageFrame
          cutPlane={cutPlane}
          disableSlider={jobType === SEGMENTATION_JOB}
          job={job}
          title="Output"
          updatePlane={(value) => updateCutPlane(value)}
          updateZoomEvent={(value) => updateZoom(value)}
          xyImageName={basename + "/" + prefix + "XY.png/" + jid}
          xzImageName={basename + "/" + prefix + "XZ.png/" + jid}
          yzImageName={basename + "/" + prefix + "YZ.png/" + jid}
          zoption={zOption}
        />
      </div>
    )
  }
  return (
    <div className="images-container">
      <ImageFrame
        cutPlane={cutPlane}
        disabled={true}
        title="Input"
        updatePlane={(value) => updateCutPlane(value)}
        updateZoomEvent={(value) => updateZoom(value)}
        zoption={zOption}
      />
      <ImageFrame
        cutPlane={cutPlane}
        disabled={true}
        title="Output"
        updatePlane={(value) => updateCutPlane(value)}
        updateZoomEvent={(value) => updateZoom(value)}
        zoption={zOption}
      />
    </div>
  )
}
export default memo(PrintImages)
