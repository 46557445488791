import axios from "axios"
import React, { useState } from "react"
import { toast } from "react-toastify"

import { getCookie } from "../../helpers/auth"
import ConsoleHelper from "../../helpers/ConsoleHelper"

const Admin = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    textChange: "Submit",
    role: ""
  })

  const { name, email, company, textChange } = formData

  const handleChange = (text) => (e) => {
    let value = e.target.value
    if (text === "company") value = value.charAt(0).toUpperCase() + value.slice(1)
    if (text === "email") value = value.toLowerCase()
    setFormData((prevForm) => ({ ...prevForm, [text]: value }))
  }

  const handleSubmit = (e) => {
    const token = getCookie("token")
    e.preventDefault()
    setFormData((prevForm) => ({ ...prevForm, textChange: "Submitting" }))
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/backend/adduser`,
        {
          email,
          name,
          company
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(() => {
        setFormData({
          email: "",
          name: "",
          company: "",
          textChange: "Submitted"
        })
        toast.success("Sent new invitation")
      })
      .catch((err) => {
        //console.log(err)
        ConsoleHelper("Error: add user in:" + err.response)
        setFormData((prevForm) => ({ ...prevForm, textChange: "Not submitted" }))

        if (err.response.data.errors) toast.error(err.response.data.errors)
        else if (err.response) {
          toast.error("Error connecting to Server - " + err.response.status + " " + err.response.statusText)
        } else {
          toast.error("Error: No connection to server. Contact support")
        }
      })
  }

  return (
    <div className="min-h-full pt-4 text-gray-900 flex justify-center">
      <div
        className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow sm:rounded-lg flex 
      justify-center flex-1"
      >
        <div className="mt-4 flex flex-col items-center">
          <div className="w-full flex-1 mt-2 text-indigo-500">
            <h2 className="text-2xl xl:text-3xl font-extrabold">Add New User</h2>

            <form className="w-full flex-1 mt-3 text-indigo-500" onSubmit={handleSubmit}>
              <div className="mx-auto max-w-xs relative ">
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                  border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                  focus:border-gray-400 focus:bg-white mt-2"
                  onChange={handleChange("email")}
                  placeholder="Email"
                  type="email"
                  value={email}
                />
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                  border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                  focus:border-gray-400 focus:bg-white mt-2"
                  onChange={handleChange("name")}
                  placeholder="Name"
                  type="text"
                  value={name}
                />
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                  border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                  focus:border-gray-400 focus:bg-white mt-2"
                  onChange={handleChange("company")}
                  placeholder="Company"
                  type="text"
                  value={company}
                />

                <button
                  className="mt-2 tracking-wide font-semibold bg-indigo-500 text-gray-100
                  w-full py-2 rounded-lg hover:bg-indigo-700 transition-all duration-300
                  ease-in-out flex items-center justify-center focus:shadow-outline 
                  focus:outline-none"
                  type="submit"
                >
                  <i className="fas fa-user-plus fa 1x w-6  -ml-2" />
                  <span className="ml-3">{textChange}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin
