import axios from "axios"
import jwt_decode from "jwt-decode"
import React, { useContext, useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { authenticate } from "../../helpers/auth"
import ConsoleHelper from "../../helpers/ConsoleHelper"
import { SessionContext } from "../../helpers/SessionContext"

const Activate = () => {
  const { session, setSession } = useContext(SessionContext)

  const [formData, setFormData] = useState({
    name: "",
    token: "",
    show: true
  })
  const params = useParams()

  useEffect(() => {
    let token = params.token
    let { name } = jwt_decode(token)

    if (token) {
      setFormData((prevForm) => ({ ...prevForm, name, token }))
    }
  }, [params])
  const { name, token } = formData

  const handleSubmit = (e) => {
    e.preventDefault()
    ConsoleHelper("In handle submit")
    axios
      .post(`${process.env.REACT_APP_API_URL}/backend/activation`, {
        token
      })
      .then((res) => {
        setFormData((prevForm) => ({ ...prevForm, show: false }))

        ConsoleHelper("Successfull activation")
        toast.success(res.data.message)
        authenticate(res, () => {
          setFormData((prevForm) => ({
            ...prevForm,
            email: "",
            password1: "",
            textChange: "Submitted"
          }))
          setSession(true)
          toast.success(`Hey ${res.data.user.name}, Welcome back!`)
        })
      })
      .catch((err) => {
        if (err.response.data.errors) toast.error(err.response.data.errors)
        else toast.error("Error connecting to Server - " + err.response.status + " " + err.response.statusText)
      })
  }

  return (
    <div className="min-h-full pt-4 text-gray-900 flex justify-center">
      {session ? <Navigate to="/projects" /> : null}
      <div
        className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow sm:rounded-lg 
      flex justify-center flex-1"
      >
        <div className="mt-4 flex flex-col items-center">
          <div className="w-full flex-1 mt-2 text-indigo-500">
            <h2 className="text-2xl xl:text-3xl font-extrabold">Welcome {name}</h2>

            <form className="w-full flex-1 mt-8 text-indigo-500" onSubmit={handleSubmit}>
              <div className="mx-auto max-w-xs relative ">
                <button
                  className="mt-2 tracking-wide font-semibold bg-indigo-500 text-gray-100 
                  w-full py-2 rounded-lg hover:bg-indigo-700 transition-all duration-300 
                  ease-in-out flex items-center justify-center focus:shadow-outline 
                  focus:outline-none"
                  type="submit"
                >
                  <i className="fas fa-user-plus fa 1x w-6 ml-3" />
                  <span className="ml-3 mr-3">Activate your Account</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activate
