import "./Parameters.css"

import React from "react"

import { ExpandableList } from "../ExpandableList"

const SegParameters = ({  parameter, onChange, openAdvancedDefault }) => {
  const seg = parameter

  return (
    <ExpandableList
      ariaLabel="default netsim options"
      label={"Default Options"}
      list={[
        {
          label: "Model",
          value: seg?.model ?? "StableST_v1.0",
          type: "select",
          id: "Model",
          selectOptions: [
            {
              label: "StableST_Old",
              value: "Stable_256"
            },
            {
              label: "StableST_v1.0",
              value: "StableST_v1"
            },
            {
              label: "DevST&CB_v1.4",
              value: "DevST&CB_v1.4"
            }
          ],
          onChange: (x) => {
            onChange({ ...seg, model: x })
          }
        },
        {
          label: "Min Norm",
          value: seg?.thresholdMin ?? 0.0,
          step: 0.01,
          type: "number",
          onChange: (x) => {
            let number = Number(x)
            if (isNaN(number)) number = 0
            number = Math.min(Math.max(number, 0.0), 1.0)
            onChange({ ...seg, thresholdMin: number })
          }
        },
        {
          label: "Max Norm",
          value: seg?.thresholdMax ?? 0.0,
          step: 0.01,
          min: 0,
          max: 1,
          onChange: (x) => {
            let number = Number(x)
            if (isNaN(number)) number = 0
            number = Math.min(Math.max(number, 0.0), 1.0)
            onChange({ ...seg, thresholdMax: number })
          }
        }
      ]}
      openDefault={openAdvancedDefault}
    />
  )
}

export default SegParameters
