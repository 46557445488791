import { useEffect, useRef } from 'react'

function useInterval(callback, delay) {
  const savedCallback = useRef()
  const intervalRef = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback.current === 'function') {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      intervalRef.current = setInterval(tick, delay)
      return () => {
        clearInterval(intervalRef.current)
        intervalRef.current = undefined
      }
    }
  }, [delay])

  const handleClearInterval = ()=>{
    intervalRef.current && clearInterval(intervalRef.current)
  }


  return {
    handleClearInterval
  }
}

export default useInterval
