import React from "react"
import { Folder } from "react-feather"

export const TypeIcon = (props) => {
  if (!props.fileType) 
    return <Folder
    className="icon icon-grey"
    size={16}
  />
  
}
