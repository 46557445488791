import React from "react"
import { ChevronRight, DownloadCloud, Eye, Trash2 } from "react-feather"

import TooltipWrapper from "../../components/TooltipWrapper"
import styles from "../../components/tree/CustomTree.module.css"
import { isAuth } from "../../helpers/auth"

const TreeImageItem = (props) => {
  const { handleToggle, node, dragOverProps, indent, isOpen, isHasChildExpandedWrapper, hasChild, project } = props
  const { id, iid, image, isImage, text } = node
  const user = isAuth().username

  return (
    <div style={{ paddingInlineStart: indent }} {...dragOverProps}>
      <div className={`tree-node ${styles.root}`}>
        <div
          className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ""} ${
            isHasChildExpandedWrapper ? styles.isHasChild : ""
          }`}
        >
          {hasChild && (
            <div onClick={handleToggle}>
              <ChevronRight className="icon icon-grey" size={16} />
            </div>
          )}
          {!isImage && !hasChild && (
            <div onClick={handleToggle}>
              <ChevronRight className="icon icon-grey" size={16} />
            </div>
          )}
        </div>
        <div className={styles.inputWrapper}>
          <TooltipWrapper text={text}>
            <label className={styles.nodeLabel} onClick={isImage ? () => props.onChangeView(iid) : handleToggle}>
              {image ? text.slice(0, 35) : text}
            </label>
          </TooltipWrapper>

          <div className={styles.inputSubWrapper}>
            <Eye
              active={iid === props.viewID ? "t" : "f"}
              className="icon focus-red"
              onClick={() => props.onChangeView(iid)}
              size={16}
            />
            <a
              className="icon-box"
              href={
                process.env.REACT_APP_API_URL +
                "/backend/" +
                user +
                "/" +
                project.pid +
                "/download/" +
                project.images[iid]?.filename
              }
            >
              <DownloadCloud className="icon icon-green" size={16} />
            </a>
            <Trash2 className="icon icon-red" onClick={() => props.removeImage(id)} size={16} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TreeImageItem
