
import {  useEffect } from "react"

const DEFAULT_VALUE = 0.5

function useUpdateMicroView({viewId, params, updateMicroPorosity}) {
  useEffect(() => {
    updateMicroPorosity(params?.Parameters?.microPhasePorosity || DEFAULT_VALUE)
  },[viewId, params])
}

export default useUpdateMicroView

