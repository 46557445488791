import "react-image-crop/dist/ReactCrop.css"
import "./CropTool.css"

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactCrop from "react-image-crop"
import { connect } from "react-redux"
import { Progress } from "reactstrap"

import { doJob, getJobStatus, getProject, killJob } from "../actions/projectSlice"
import { ExpandableList, LaunchButton, PlaneSelector } from "../components"
import ImageFolders from "../components/ImageFolders"
import CropParameters from '../components/Parameters/CropParameters'
import { DEFAULT_POLLING_TIME } from "../constants/CommonConstants"
import { CROP_JOB, STATUS_FINISHED, STATUS_RUNNING, STATUS_UNKNOWN, STATUS_WAITING } from "../constants/jobnames"
import useJobNotification from "../hooks/useJobNotification"
import usePrevious from "../hooks/usePrevious"
import { getCropData, getCValue, getDefaultCropValues, getPlanesOrder, getServerImageSource } from "../utils/images"
import { killJobFn, sendUpdateRequestFn } from "../utils/jobs"

const CropTool = ({ project, doJob, getProject, getJobStatus, killJob }) => {
  const ref_OutputNameProjectRunning = useRef(null)
  const ref_old_imageId = useRef(null)
  const [imageId, setImageId] = useState("")
  const [imagePlane, setImagePlane] = useState(0)

  const [outputFileName, setOutputFileName] = useState("")
  const [crop, setCrop] = useState({
    x: 0,
    cx: 100,
    y: 0,
    cy: 100,
    z: 0,
    cz: 100
  })

  const intervalIdRef = useRef(null)

  const job = useMemo(
    () =>
      project.images[imageId]?.jobs?.[CROP_JOB] ?? {
        status: STATUS_UNKNOWN,
        progress: 0
      },
    [imageId, project.images]
  )
  const image = project.images[imageId]

  const sendUpdateRequest = sendUpdateRequestFn(getJobStatus)
  const killCropJob = killJobFn(killJob)
  const getImageSourceByPlane = (plane) => getServerImageSource(project.pid, image, plane)

  const updateImageId = useCallback(
    (imageId) => {
      const image = project.images[imageId]
      setCrop(getDefaultCropValues(image))
      setImageId(imageId)
    },
    [project.images]
  )

  useJobNotification({ image, job })

  useEffect(() => {
    if (project.images !== undefined && Object.keys(project.images).length > 0 && !imageId) {
      updateImageId(Object.keys(project.images)[0])
    }
    return () => clearInterval(intervalIdRef.current)
  }, [imageId, project.images, updateImageId])

  useEffect(() => {
    if (image) {
      const cropData = getCropData(image, crop)
      let newOutputFileName = `crop_${cropData.cx}_${cropData.cy}_${cropData.cz}_${image.name}`
      if (job.status === STATUS_WAITING || job.status === STATUS_RUNNING) {
        setOutputFileName(
          ref_OutputNameProjectRunning.current?.[imageId]?.[CROP_JOB] || job?.target_iid || newOutputFileName
        )
      } else {
        if (outputFileName.length && ref_old_imageId.current === image.iid) {
          const indexFlatNameDefault = outputFileName.indexOf("crop_")
          const subOutputName = outputFileName.slice(0, indexFlatNameDefault)
          newOutputFileName = subOutputName + newOutputFileName
        } else {
          ref_old_imageId.current = image.iid
        }
        setOutputFileName(newOutputFileName)
      }
    }
  }, [image, crop, imageId, job?.status, job?.target_iid])

  const pollApi = useCallback(() => {
    if (Object.keys(job).length) {
      if ((job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) || job.jid === undefined) {
        clearInterval(intervalIdRef.current)
      } else {
        sendUpdateRequest(project.pid, imageId, job.jid, CROP_JOB)
      }
    }
  }, [imageId, job, project.pid, sendUpdateRequest])

  useEffect(() => {
    if ([STATUS_WAITING, STATUS_RUNNING].includes(job.status)) {
      intervalIdRef.current = setInterval(pollApi, DEFAULT_POLLING_TIME)
    }
    return () => clearInterval(intervalIdRef.current)
  }, [job.status, pollApi, project.pid])

  function useJobFinished(val) {
    const prevVal = usePrevious(val)
    return [STATUS_RUNNING, STATUS_WAITING].includes(prevVal) && val === STATUS_FINISHED
  }
  const hasJobFinished = useJobFinished(job.status)

  useEffect(() => {
    if (hasJobFinished) getProject(project.pid)
  }, [getProject, hasJobFinished, project.pid])

  const launchJob = () => {
    const cropData = getCropData(image, crop)
    const properties = {
      ...cropData,
      out_path: outputFileName
    }
    const payload = {
      pid: project.pid,
      iid: imageId,
      iidParent: image.iid,
      jobname: CROP_JOB,
      data: properties
    }
    ref_OutputNameProjectRunning.current = {
      ...ref_OutputNameProjectRunning.current,
      [imageId]: { [CROP_JOB]: outputFileName }
    }
    doJob(payload)
  }

  const handleSetCrop = (c, plane) => {
    setCrop((prevState) => {
      const newState = { ...prevState }
      const croppingPlanes = getPlanesOrder(plane)
      newState[croppingPlanes[0]] = c.x
      newState[getCValue(croppingPlanes[0])] = c.width
      newState[croppingPlanes[1]] = c.y
      newState[getCValue(croppingPlanes[1])] = c.height
      if (prevState[getCValue(croppingPlanes[2])] === 0) {
        newState[getCValue(croppingPlanes[2])] = 100
      }
      return newState
    })
  }

  const getCropValuesByPlane = (plane) => {
    const croppingPlanes = getPlanesOrder(plane)
    return {
      x: crop[croppingPlanes[0]],
      y: crop[croppingPlanes[1]],
      width: crop[getCValue(croppingPlanes[0])],
      height: crop[getCValue(croppingPlanes[1])],
      unit: "%"
    }
  }
  
  const onChangeCropImage = useCallback((newImageId) => {
    updateImageId(newImageId)
  },[updateImageId])

  return imageId ? (
    <div className="toolTab">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>

          <h4 className="subtitle">Input Images</h4>
          <ImageFolders
            images={project.images}
            jobType={CROP_JOB}
            onChangeImage={onChangeCropImage}
            pid={project?.pid || null}
            viewID={imageId}
          />
          <CropParameters
            image={image}
            onChange={(newParameter) => setCrop(newParameter)}
            parameter={crop}
          />
          <h4 className="subtitle">Output Name</h4>
          <div className="parameter">
            <input
              className="filename"
              onChange={(e) => setOutputFileName(e.target.value)}
              placeholder="Output File Name"
              type="text"
              value={outputFileName}
            />
          </div>
        </div>
        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton
          adjust={false}
          jobStatus={job.status}
          killJob={() => killCropJob(project.pid, imageId, job.jid, CROP_JOB, intervalIdRef.current)}
          launchJob={launchJob}
          readyToLaunch={true}
        />
      </div>
      <div className="toolResultWrapper input-grid">
        <div className="centering image-holder">
          <PlaneSelector currentCutPlane={{ pos: imagePlane }} updatePlane={({ pos }) => setImagePlane(pos)} />
          <div className="image-box" style={{ height: "unset", maxHeight: "unset" }}>
            <div className="image-frame-wrapper">
              <ReactCrop
                className="image"
                crop={getCropValuesByPlane(imagePlane)}
                onChange={(newCrop, percentCrop) => handleSetCrop(percentCrop, imagePlane)}
              >
                <img alt="current plane image preview" className="image" src={getImageSourceByPlane(imagePlane)} />
              </ReactCrop>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default connect(null, { doJob, killJob, getJobStatus, getProject })(CropTool)
