import React from "react"

import { EXTRA_JOB, STATUS_FINISHED, STATUS_RUNNING, UPLOAD_JOB } from "../../constants/jobnames"
import { getServerImageSource } from "../../utils/images"
import ImageFrame from "../ImageFrame"

const PrintImages = ({ projectId, image, cutPlane, extraJob, zOption, updateCutPlane, updateZoom }) => {
  if (image !== undefined && image?.jobs?.[EXTRA_JOB]?.status === STATUS_FINISHED) {
    return (
      <ImageFrame
        cutPlane={cutPlane}
        job={extraJob}
        title="2D Viewer"
        updatePlane={(value) => updateCutPlane(value)}
        updateZoomEvent={(value) => updateZoom(value)}
        xyImageName={getServerImageSource(projectId, image, 0)}
        xzImageName={getServerImageSource(projectId, image, 1)}
        yzImageName={getServerImageSource(projectId, image, 2)}
        zoption={zOption}
      />
    )
  } else {
    let newJob = {}
    if (image !== undefined && image?.jobs?.[UPLOAD_JOB]?.status === STATUS_RUNNING) newJob = image?.jobs?.[UPLOAD_JOB]
    else newJob = extraJob
    return (
      <ImageFrame
        cutPlane={cutPlane}
        job={newJob}
        title="2D Viewer"
        updatePlane={(value) => updateCutPlane(value)}
        updateZoomEvent={(value) => {}}
        zoption={zOption}
      />
    )
  }
}

export default PrintImages
