import "./ImageFrame.css"

import Slider from "@mui/material/Slider"
import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import { CloudOff, Eye, EyeOff, Maximize, X, ZoomIn, ZoomOut } from "react-feather"
import UseAnimations from "react-useanimations"
import airplay from "react-useanimations/lib/airplay"
import waiting from "react-useanimations/lib/loading"
import loading from "react-useanimations/lib/loading2"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

import { PlaneSelector } from "../components"
import {
  STATUS_FAILED,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
  UPLOAD_JOB
} from "../constants/jobnames"
import ConsoleHelper from "../helpers/ConsoleHelper"
import useDebounceFn from "../hooks/useDebounceFn"

const MAX_SCALE_ZOOM_IN = 10

const ImageFrame = (props) => {
  const [disabled, setDisabled] = useState(true)
  const [sliderState, setSliderState] = useState({
    min: 0,
    max: 100,
    brightness: 1,
    contrast: 1
  })
  const transWrapperRef = useRef(null)

  useEffect(() => {
    if (transWrapperRef.current !== null) {
      if (!props.zoption) return
      transWrapperRef.current.setTransform(props.zoption.pX, props.zoption.pY, props.zoption.sC)
    }
  }, [props.zoption])

  useEffect(() => {
    if (props.job !== undefined && props.job.status === STATUS_FINISHED) {
      if (disabled) setDisabled(false)
    } else if (!disabled) setDisabled(true)
  }, [disabled, props.job?.status])

  const imageName = useMemo(() => {
    if (disabled) return props.xyImageName
    const nameImages = [props.xyImageName, props.xzImageName, props.yzImageName]
    return nameImages?.[props.cutPlane.pos] || nameImages[0]
  }, [disabled, props.cutPlane, props.xyImageName, props.xzImageName, props.yzImageName])

  const ShowIcon = () => {
    if (props.job !== undefined) {
      switch (props.job.status) {
        case STATUS_RUNNING: {
          if (props.job.jobname === UPLOAD_JOB)
            return <UseAnimations animation={airplay} className="ml-2 mr-2 darkgray-icon" size={20} />
          else return <UseAnimations animation={loading} className="ml-2 mr-2 darkgray-icon" size={20} />
        }
        case STATUS_WAITING: {
          return <UseAnimations animation={waiting} className="ml-2 mr-2 darkgray-icon" size={20} />
        }
        case STATUS_FINISHED: {
          return <Eye className="ml-2 mr-2 green-icon" size={18} />
        }
        case STATUS_FAILED: {
          return <X className="ml-2 mr-2 red-icon" size={18} />
        }
        case STATUS_UNKNOWN: {
          return <CloudOff className="ml-2 mr-2 darkgray-icon" size={18} />
        }
        default: {
          return <EyeOff className="ml-2 mr-2 gray-icon" size={18} />
        }
      }
    } else {
      return <></>
    }
  }

  const onZoomUpdate = useDebounceFn((res) => {
    if (res.state) {
      props.updateZoomEvent({
        pX: res.state.positionX,
        pY: res.state.positionY,
        sC: res.state.scale
      })
    }
  }, 300)

  const onPanUpdate = useDebounceFn((res) => {
    if (res.state) {
      props.updateZoomEvent({
        pX: res.state.positionX,
        pY: res.state.positionY,
        sC: res.state.scale
      })
    }
  }, 500)

  const onStopSlider = (e, [min, max]) => {
    let brightness = (min + max) / 100
    if (max > 100) brightness = (min + 100) / 100 + ((max - 100) / 100) * 5
    let contrast = 1 //(Math.abs(max - min))/100
    if (max - min < 100) contrast = 1 / ((max - min) / 100)
    setSliderState({ min, max, brightness, contrast })
  }

  const zoomInImage = () => {
    props.updateZoomEvent({
      sC: props.zoption.sC + 0.2
    })
  }

  const zoomOutImage = () => {
    props.updateZoomEvent({
      sC: props.zoption.sC - 0.2
    })
  }

  const resetImage = () => {
    props.updateZoomEvent({
      pX: 0,
      pY: 0,
      sC: 1
    })
  }

  const VerticalSlider = () => {
    return (
      <Slider
        aria-labelledby="discrete-slider-always"
        defaultValue={[sliderState.min, sliderState.max]}
        marks={[
          { value: 0, label: "" },
          { value: 100, label: "" }
        ]}
        max={200}
        min={0}
        onChangeCommitted={onStopSlider}
        orientation="vertical"
        size={"small"}
        step={1}
      />
    )
  }

  return (
    <div className="image-holder">
      <div className="inline-title">
        <ShowIcon />
        <h4> {props.title} </h4>
      </div>
      <div className="image-box" disabled={disabled}>
        <div className="image-frame-wrapper">
          {!disabled ? (
            <TransformWrapper
              centerOnInit={false}
              centerZoomedOut={false}
              doubleClick={{
                disabled: true,
                mode: "reset"
              }}
              initialPositionX={0}
              initialPositionY={0}
              initialScale={1}
              limitToBounds={false}
              maxPositionX={0}
              maxPositionY={0}
              maxScale={MAX_SCALE_ZOOM_IN}
              minPositionX={0}
              minPositionY={-1000}
              minScale={1}
              onPanning={onPanUpdate}
              onZoom={onZoomUpdate}
              panning={{
                lockAxisX: false, //(isMaxScale) ? true : false,
                velocityEqualToMove: true,
                velocityDisabled: false
              }}
              ref={transWrapperRef}
            >
              {() => (
                <div className="image-frame" disabled={disabled} onDoubleClick={resetImage}>
                  {props.disableButtons !== true ? (
                    <div className="zoom-buttons-holder">
                      <button className="zoom-button zoom-in" onClick={zoomInImage}>
                        <ZoomIn className="ml-2 mr-2 mt-1 mb-1" size={20} />
                      </button>
                      <button className="zoom-button zoom-out" onClick={zoomOutImage}>
                        <ZoomOut className="ml-2 mr-2 mt-1 mb-1" size={20} />
                      </button>
                      <button className="zoom-button reset" onClick={resetImage}>
                        <Maximize className="ml-2 mr-2 mt-1 mb-1" size={20} />
                      </button>
                    </div>
                  ) : null}
                  {props.disableSlider !== true ? (
                    <div className="slider-holder">
                      <VerticalSlider />
                    </div>
                  ) : null}
                  <TransformComponent>
                    <img
                      alt=""
                      className="image"
                      src={imageName}
                      style={{
                        filter: `brightness(${sliderState.brightness}) contrast(${sliderState.contrast})`
                      }}
                    />
                  </TransformComponent>
                </div>
              )}
            </TransformWrapper>
          ) : (
            <div className="image-frame" disabled={disabled}>
              <img alt="new" className="image" disabled={true} src={require("../images/noImage.png")} />
            </div>
          )}
        </div>
      </div>
      <PlaneSelector currentCutPlane={props.cutPlane} disabled={disabled} updatePlane={props.updatePlane} />
    </div>
  )
}

export default memo(ImageFrame)
