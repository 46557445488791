import axios from "axios"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const ForgetPassword = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: "",
    textChange: "Submit"
  })
  const { email } = formData

  const handleChange = (text) => (e) => {
    let value = e.target.value
    if (text === "email") value = value.toLowerCase()
    setFormData((prevForm) => ({ ...prevForm, [text]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (email) {
      setFormData((prevForm) => ({ ...prevForm, textChange: "Submitting" }))
      axios
        .put(`${process.env.REACT_APP_API_URL}/backend/forgotpassword`, {
          email
        })
        .then(() => {
          setFormData({
            ...formData,
            email: ""
          })
          toast.success("Reset Password link sent to your email")
          navigate("/login")
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.errors) {
              toast.error("Error connecting to Server - " + err.response.status + " " + err.response.statusText)
            } else {
              toast.error(err.response.data.errors)
            }
          } else {
            toast.error("Error: No connection to server. Contact support")
          }
        })
    } else {
      toast.error("Please fill all fields")
    }
  }

  return (
    <div className="min-h-full text-gray-900 flex justify-center pt-4">
      <div
        className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow sm:rounded-lg 
      flex justify-center flex-1"
      >
        <div className="">
          <div className="mt-4 flex flex-col items-center">
            <div className="w-full flex-1 mt-2 text-indigo-500">
              <h2 className="text-2xl xl:text-3xl font-bold">Forgot your password ?</h2>
              <div className="w-full flex-1 mt-4 text-indigo-500">
                <form className="mx-auto max-w-xs relative " onSubmit={handleSubmit}>
                  <input
                    className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white"
                    onChange={handleChange("email")}
                    placeholder="Email"
                    type="email"
                    value={email}
                  />
                  <button
                    className=" w-full py-3 mt-4 tracking-wide font-semibold bg-indigo-500 
                    text-gray-100 rounded-lg hover:bg-indigo-700 transition-all duration-300 
                    ease-in-out flex items-center justify-center focus:shadow-outline 
                    focus:outline-none"
                    type="submit"
                  >
                    <i className="fas fa-sign-in-alt w-6" />
                    <span className="ml-3">Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
