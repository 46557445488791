import InnerHTML from "dangerously-set-html-content"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { Progress } from "reactstrap"

import { doJob, getJobStatus, getProject, killJob } from "../actions/projectSlice"
import ImageFolders from "../components/ImageFolders"
import { NETWORK_JOB, STATUS_FINISHED, STATUS_RUNNING, STATUS_UNKNOWN, STATUS_WAITING } from "../constants/jobnames"
import ConsoleHelper from "../helpers/ConsoleHelper"
import useMicroPorosity from "../hooks/screens/NetworkTool/useMicroPorosity"
import useUpdateMicroView from "../hooks/screens/NetworkTool/useUpdateMicroView"
import useJobNotification from "../hooks/useJobNotification"

const POLLING_TIME = 3000
const INPUT_TYPE = "8b"
const NAME_PREFIX = "net_"

function NetworkTool(props) {
  const [selectedImage, setSelectedImage] = useState(null)
  const [readyToLaunch, setReadyToLaunch] = useState(false)
  const [outputName, setOutputName] = useState("")
  const [viewID, setViewID] = useState("")
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })
  const intervalId = useRef(null)

  const currentImage = props.project.images[viewID]
  const extraJob = currentImage?.jobs?.[NETWORK_JOB]
  
  const {microPorosity, updateMicroPorosity}= useMicroPorosity({defaultValue:0.5})


  useUpdateMicroView({viewId:viewID,params:extraJob?.parameters,updateMicroPorosity})

  const sendUpdateRequest = useCallback((iid, jid) => {
    if (viewID !== "" && job.jid !== undefined) {
      const payload = {
        pid: props.project.pid,
        iid,
        jid,
        jobname: NETWORK_JOB
      }
      props.getJobStatus(payload)
    }
  }, [job.jid, props, viewID])

  const changeView = useCallback((index) => {
    clearInterval(intervalId.current)
    setViewID(index)
    let image = props.project.images[index]
    setSelectedImage(image)
    setOutputName(NAME_PREFIX + image.name)
    if (image.jobs !== undefined && image.jobs[NETWORK_JOB] !== undefined) {
      setJob(image.jobs[NETWORK_JOB])
    } else {
      setJob({ status: STATUS_UNKNOWN, progress: 0 })
    }
  }, [props?.project?.images?.length])

  useEffect(() => {
    props.getProject(props.project.pid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let images = props.project.images
    let nImages = Object.keys(images).filter((iid) => images[iid].type === INPUT_TYPE)

    if (nImages.length > 0) changeView(nImages[0])

    return () => clearInterval(intervalId.current)
  }, [props?.project?.images?.length, changeView])

  useEffect(() => {
    let image = props.project.images[viewID]
    if (image !== undefined && image.jobs !== undefined && image.jobs[NETWORK_JOB] !== undefined) {
      let jobImage = image.jobs[NETWORK_JOB]
      if (jobImage.status !== job.status || jobImage.progress !== job.progress || jobImage.results !== job.results) {
        if ((jobImage.status !== STATUS_RUNNING && jobImage.status !== STATUS_WAITING) || jobImage.jid === undefined) {
          clearInterval(intervalId.current)
        }
        if (job.status !== STATUS_FINISHED && jobImage.status === STATUS_FINISHED) {
          props.getProject(props.project.pid)
        }
        setJob(jobImage)
      }
    }
  }, [viewID, job, props])

  useJobNotification({ job })

  const pollApi = useCallback( () => {
    // ConsoleHelper("JobStatus Polling ", this.state.job)
    if (Object.keys(job).length) {
      if (!((job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) || job.jid === undefined)) {
        sendUpdateRequest(viewID, job.jid)
      }
    }
  }, [job, sendUpdateRequest, viewID])

  useEffect(() => {
    if (job.status === STATUS_WAITING || job.status === STATUS_RUNNING) {
      intervalId.current = setInterval(pollApi, POLLING_TIME)
    }
    return () => clearInterval(intervalId.current)
  }, [job, pollApi])

  const checkLaunch = useCallback(() => {
    if (selectedImage != null && job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) {
      setReadyToLaunch(true)
    } else {
      setReadyToLaunch(false)
    }
  }, [job?.status, selectedImage])

  const onChange = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), 1.0)

    if (e.target.id === "microPorosity") {
      updateMicroPorosity(number)
    }
  }

  useEffect(() => {
    checkLaunch()
  }, [selectedImage, job, checkLaunch])

  const onChangeImage = useCallback((value) => {
    changeView(value)
  }, [changeView])

  const launchJob = () => {
    ConsoleHelper("Launching Job")
    let properties = {
      out_path: outputName,
      microPorosity: microPorosity
    }
    let payload = {
      pid: props.project.pid,
      iid: selectedImage.iid,
      jobname: NETWORK_JOB,
      data: properties
    }
    props.doJob(payload)
  }

  const killJob = () => {
    clearInterval(intervalId.current)
    if (job.jid !== undefined) {
      const payload = {
        pid: props.project.pid,
        iid: viewID,
        jid: job.jid,
        jobname: NETWORK_JOB
      }
      ConsoleHelper("Killing Job: " + JSON.stringify(payload))
      props.killJob(payload)
    }
  }

  const memoizedImage = useMemo(()=>{
    return props.project.images
  },[JSON.stringify(props.project.images)])

  const memoizedPid = useMemo(()=>{
    return props.project?.pid || null
  },[props.project?.pid])

  const onChangeNotPM = useCallback((node)=>{
    return node.createByTool !== INPUT_PM_TYPE
  },[])

  const LaunchButton = () => {
    if (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) {
      return (
        <button className="btn btn-success btn-block" disabled={!readyToLaunch} onClick={launchJob} type="button">
          Launch
        </button>
      )
    } else {
      return (
        <button className="btn btn-warning btn-block" onClick={killJob} type="button">
          Cancel
        </button>
      )
    }
  }

  const NetworkData = useCallback(() => {
    if(!job?.results) return <h5 className="no-results-netex">No Results Yet</h5>

      let network = job.results.Network
      let properties = job.results.Properties
      return (
        <>
          <h4 className="subtitle"> Network</h4>
          <div className="parameter">
            <label className="result-label">
              Nodes:
              <label className="result">{network.Nodes}</label>
            </label>
            <label className="result-label">
              Links:
              <label className="result">{network.Links}</label>
            </label>
            <label className="result-label">
              AspectRatio:
              <label className="result">{network.AspectRatio.toFixed(2)}</label>
            </label>
            <label className="result-label">
              CoordNumber:
              <label className="result">{network.CoordinationNumber.toFixed(2)}</label>
            </label>
          </div>
          <h4 className="subtitle"> Properties</h4>
          <div className="parameter">
            <label className="result-label">
              TotalPorosity [%]:
              <label className="result">{(properties.TotalPorosity * 100).toFixed(2)}</label>
            </label>
            <label className="result-label">
              OpenPorosity [%]:
              <label className="result">{(properties.OpenPorosity * 100).toFixed(2)}</label>
            </label>
            <label className="result-label">
              Perm. (X-Y-Z) [mD]: (
              <label className="result">
                {parseInt(properties.PermX)} - {parseInt(properties.PermY)} - {parseInt(properties.PermZ)}
              </label>
              )
            </label>
            <label className="result-label">
              FormFactor (X-Y-Z): (
              <label className="result">
                {properties.FFX.toFixed(2)} - {properties.FFY.toFixed(2)} - {properties.FFZ.toFixed(2)}
              </label>
              )
            </label>
            <label className="result-label">
              Tortuosity (X,Y,Z): (
              <label className="result">
                {properties.TortuosityX.toFixed(2)} - {properties.TortuosityY.toFixed(2)} -{" "}
                {properties.TortuosityZ.toFixed(2)}
              </label>
              )
            </label>
          </div>
        </>
      )
   
  },[JSON.stringify(job.results)])

  return (
    <div className="toolTab network-tool">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">
            <Row className="row-parameter">
              <Col xs={8}> Micro-Phase Porosity </Col>
              <Col>
                <input
                  className="number-parameter"
                  id="microPorosity"
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.1"
                  type="number"
                  value={microPorosity}
                />
              </Col>
            </Row>
          </div>
          <h4 className="subtitle"> Input Image [{INPUT_TYPE}]</h4>
          <ImageFolders
            imageType={INPUT_TYPE}
            images={memoizedImage}
            jobType={NETWORK_JOB}
            onChangeImage={onChangeImage}
            pid={memoizedPid}
            viewID={viewID}
          />
        </div>
        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton />
      </div>
      <div className="toolResultWrapper input-grid">
          <div className="xPlot-holder xPlot-holder-result  centering">
            <div className="xPlot-holder-content">
              <h5 className="title"> Results </h5>
              <NetworkData />
            </div>
          </div>
        <div className="xPlot-holder xPlot-holder-cross  centering">
          <div className="xPlot-holder-content">
            <h5 className="title"> Cross Plots </h5>
            {job?.results?.xplot ? (
              <div className="xplot-image">
                <InnerHTML html={job?.results?.xplot} key={job.jid} />
              </div>
            ) : (
              <h5 className="no-results-netex">No Results Yet</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

NetworkTool.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired
  }).isRequired,
  doJob: PropTypes.func.isRequired,
  killJob: PropTypes.func.isRequired,
  getJobStatus: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired
}

export default connect(null, { doJob, killJob, getJobStatus, getProject })(NetworkTool)
