import "./ImageFrame.css"

import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"

const ImageFrame = (props) => {
  const { files, title, inputFileNames } = props
  const isFiles = files.length > 0

  const [images, setImages] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const fileNames = useMemo(() => Object.values(props.files).map((e) => e.name ?? ""), [props.files])

  useEffect(() => {
    setImages([...files])
    setCurrentIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileNames.toString()])

  const isNoImage = images[currentIndex] != undefined || inputFileNames[currentIndex] != undefined
  const sizeImage = isFiles ? images.length : inputFileNames.length

  const isDisableImage = isFiles ? !images[currentIndex] : !inputFileNames[currentIndex]
  return (
    <div className="imagePreviewWrapper">
      <div className="image-holder">
        <div className="inline-title">
          <h4> {title ?? ""} </h4>
        </div>
        <div className="image-box" disabled={isDisableImage}>
          <div className="image-frame-wrapper">
            {sizeImage > 1 && (
              <span className="orderImage" hidden={sizeImage.length < 2}>
                {currentIndex + 1}/{sizeImage}
              </span>
            )}
            <div className="image-frame" disabled={isDisableImage}>
              {!isNoImage ? (
                <img alt="new" className="image" disabled={true} src={require("../images/noImage.png")} />
              ) : // eslint-disable-next-line max-len
              isFiles ? (
                <ImageView imageFile={images[currentIndex]} />
              ) : (
                <ImageViewSV imageFile={inputFileNames[currentIndex]} />
              )}
            </div>
          </div>
        </div>

        {sizeImage > 1 && (
          <ImagePreviewControl
            indexSelected={currentIndex}
            onChange={(_indexSelected) => setCurrentIndex(_indexSelected)}
            totalImages={sizeImage}
          />
        )}
      </div>
    </div>
  )
}

export default ImageFrame

const ImagePreviewControl = (props) => {
  let { indexSelected = 0, totalImages, onChange } = props

  const handleNext = () => {
    if (indexSelected === totalImages - 1) return onChange(0)
    onChange(++indexSelected)
  }

  const handlePre = () => {
    if (indexSelected === 0) return onChange(totalImages - 1)
    onChange(--indexSelected)
  }

  return (
    <React.Fragment>
      <button className="imagePreviewControl left" onClick={handlePre}>
        <ChevronLeft size={20} />
      </button>
      <button className="imagePreviewControl right" onClick={handleNext}>
        <ChevronRight size={20} />
      </button>
    </React.Fragment>
  )
}

const ImageView = memo((props) => {
  const reviewImage_ref = useRef(undefined)
  const { imageFile } = props

  useEffect(() => {
    if (reviewImage_ref.current && imageFile) {
      const src = URL.createObjectURL(imageFile)
      reviewImage_ref.current.src = src
      reviewImage_ref.current.onload = function () {
        URL.revokeObjectURL(src)
      }
    }
  }, [imageFile])

  return <img alt="Picture review" className="imagePreview" ref={reviewImage_ref} src={""} />
})

const ImageViewSV = memo((props) => {
  const { imageFile } = props

  return <img alt="Picture review" className="imagePreview" src={imageFile} />
})
