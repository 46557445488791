// TODO: What is used in this css chould be put here
import "../screens/Parameters.css"

import React, { useState } from "react"
import { Col, Dropdown, Row } from "react-bootstrap"

export const ExpandableList = ({ ariaLabel, label, list, openDefault }) => {
  const [isOpen, setIsOpen] = useState(openDefault || false)

  const toggle = () => {
    setIsOpen((prevState) => !prevState)
  }

  const ExpandableItem = (row) => {
    switch (row.type) {
      case "checkbox":
        if (!row?.id) throw new Error("Checkbox must have an id")
        return (
          <div className="custom-control custom-checkbox">
            <input
              checked={row.value}
              className="custom-control-input"
              id={row?.id}
              onChange={(e) => row.onChange(e.target.checked)}
              type="checkbox"
            />
            <label className="label-parameter custom-control-label" htmlFor={row?.id}></label>
          </div>
        )
      case "select":
        return (
          <Dropdown>
            <Dropdown.Toggle
              className="dropdown-font custom-btn"
              id={row?.id ?? "dropdown-basic"}
              size="sm"
              variant="info"
            >
              {row.value.toString()}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-font" style={{ margin: 0 }}>
              {row.selectOptions.map((option, index) => (
                <Dropdown.Item key={index} onClick={() => row.onChange(option.value)}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      default:
        return (
          <input
            className="number-parameter"
            max={row.max}
            min={row.min}
            onChange={(e) => row.onChange(e.target.value)}
            step={row.step ?? "1"}
            type={row.type ?? "number"}
            value={row.value.toString()}
          />
        )
    }
  }

  return (
    <>
      <a
        aria-controls="collapseAdvanceMenu"
        aria-expanded="true"
        className="parameter_btn"
        onClick={toggle}
        role="button"
      >
        <i className={isOpen ? "fa fa-minus fa-fw custom-btn-icon" : "fa fa-plus fa-fw custom-btn-icon"} /> {label}
      </a>
      {isOpen ? (
        <div className="checkbox-holder-parameter">
          <div className="card-body-parameter card-body">
            {list.map((row, index) => (
              <Row className="row-parameter" key={`${ariaLabel}_${index}`}>
                <Col xs={8}>{row.label}</Col>
                <Col className="d-flex justify-content-end relative" xs={4}>
                  {ExpandableItem(row)}
                </Col>
              </Row>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}
