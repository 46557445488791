import React, { memo } from "react"

import ImageFrame from "../ImageFrame"
const JOB_PREFIX = "seg_"

const OutputFrame = ({ job, adjust, cutPlane, user, pid, viewID, JOB_TYPE, zoption, updateZoom, updateCutPlane }) => {
  let imageID = viewID
  let jobType = JOB_TYPE
  let jid = job.jid
  if (adjust && adjustJob.status === STATUS_FINISHED && job.status === STATUS_FINISHED) {
    if (adjustJob?.real_iid) {
      imageID = adjustJob.real_iid
      jobType = ADJUSTMENT_JOB
      jid = adjustJob.jid
    }
  }
  let basename =
    process.env.REACT_APP_API_URL + "/backend/" + user + "/" + pid + "/" + imageID + "/" + jobType + "/" + JOB_PREFIX

  return (
    <ImageFrame
      adjust={adjust}
      cutPlane={cutPlane}
      disableSlider={true}
      job={job}
      title="Output"
      updatePlane={(value) => updateCutPlane(value)}
      updateZoomEvent={(value) => updateZoom(value)}
      xyImageName={basename + "XY.png" + "/" + jid}
      xzImageName={basename + "XZ.png" + "/" + jid}
      yzImageName={basename + "YZ.png" + "/" + jid}
      zoption={zoption}
    />
  )
}
export default memo(OutputFrame)
