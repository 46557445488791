import ConsoleHelper from '../helpers/ConsoleHelper'

export const sendUpdateRequestFn = getJobStatus => (pid, iid, jid, job_type) => {
  // ConsoleHelper("Sending job request ", jid)
  if (iid !== '' && iid !== undefined && jid !== undefined) {
    const payload = {
      pid,
      iid,
      jid,
      jobname: job_type,
    }
    return getJobStatus(payload)
  }
  else {
    ConsoleHelper('Not defined JID, [' + jid + ']')
  }
}

export const killJobFn = killJob => (pid, iid, jid, job_type, intervalId) => {
  clearInterval(intervalId)
  if (jid !== undefined) {
    const payload = {
      pid,
      iid,
      jid,
      jobname: job_type,
    }
    ConsoleHelper('Killing JoB: ' + payload)
    killJob(payload)
  }
  else {
    ConsoleHelper('Not defined Cancel JID, [' + jid + ']')
  }
}