import React, { memo } from "react"

import { STATUS_FINISHED } from "../../constants/jobnames"
import ImageFrame from "../ImageFrame"
import OutputFrame from "./OutputFrame"

const appURL = process.env.REACT_APP_API_URL

const PrintImages = ({ job, adjust, user, pid, viewID, JOB_TYPE, cutPlane, zOption, updateCutPlane, updateZoom }) => {
  const statusJob = job.status
  if (statusJob === STATUS_FINISHED) {
    const xyImageName = [appURL, "backend", user, pid, viewID, JOB_TYPE, "tomo_XY.png"].join("/")
    const xzImageName = [appURL, "backend", user, pid, viewID, JOB_TYPE, "tomo_XZ.png"].join("/")
    const yzImageName = [appURL, "backend", user, pid, viewID, JOB_TYPE, "tomo_YZ.png"].join("/")
    return (
      <div className="images-container">
        <ImageFrame
          cutPlane={cutPlane}
          job={job}
          title="Input"
          updatePlane={(value) => updateCutPlane(value)}
          updateZoomEvent={(value) => updateZoom(value)}
          xyImageName={xyImageName}
          xzImageName={xzImageName}
          yzImageName={yzImageName}
          zoption={zOption}
        />
        <OutputFrame
          JOB_TYPE={JOB_TYPE}
          adjust={adjust}
          cutPlane={cutPlane}
          job={job}
          pid={pid}
          updateCutPlane={updateCutPlane}
          updateZoom={updateZoom}
          user={user}
          viewID={viewID}
          zoption={zOption}
        />
      </div>
    )
  }

  return (
    <div className="images-container">
      <ImageFrame
        cutPlane={cutPlane}
        disabled={true}
        title="Input"
        updatePlane={(value) => updateCutPlane(value)}
        updateZoomEvent={(value) => updateZoom(value)}
        zoption={zOption}
      />
      <ImageFrame
        cutPlane={cutPlane}
        disabled={true}
        title="Output"
        updatePlane={(value) => updateCutPlane(value)}
        updateZoomEvent={(value) => updateZoom(value)}
        zoption={zOption}
      />
    </div>
  )
}

export default memo(PrintImages)
