// import ConsoleHelper from '../helpers/ConsoleHelper'

export const getExt = (filename) => {
  return filename.split('.').pop()
}

export const getPropertiesFromFilename = (filename) => {
  // Define New State
  const newState = {}
  // ConsoleHelper('Filename Effect', filename)
  // Remove first name string
  filename = filename
    .replace('.raw', '')
    .split('_')
    .slice(1)
    .join('_')
    .split(/\s*[_x}{-]\s*/)
  // ConsoleHelper('Filename Effect 1', filename)
  const utypes = ['um', 'p', 'pt']
  let result = []
  filename.forEach((item) => {
    if (!isNaN(item)) {
      result.push(item)
    } else {
      // let nitem = item
      let uDefaultUnit = item.slice(-2)

      let ditem = item.replace('mm', '')
      let nditem = ditem.replace(/[^a-zA-Z ]/g, '')
      const utype = utypes.find((uitem) => uitem === nditem)
      if (utype) {
        if (uDefaultUnit === 'mm') {
          let duItem = ditem.replace(nditem, '.')
          duItem *= 1000
          result.push(parseFloat(duItem).toFixed(4).toString())
        } else {
          let duFinalItem = ditem.replace(nditem, '.')
          // ConsoleHelper('Filename Effect 2', ditem.replace(nditem, '.'))
          if (duFinalItem.slice(-1) === '.') {
            result.push(duFinalItem.slice(0, -1))
          } else {
            result.push(duFinalItem)
          }
        }
      }
    }
  })

  filename = result
  // Get type
  const types = ['8b', '16b', '32b']
  const type = types.find((item) => item === filename[0])
  if (type) {
    newState.type = type
    filename = filename.slice(1)
  }
  // Get size x_y_z
  newState.nx = parseInt(filename[0])
  newState.ny = parseInt(filename[1])
  newState.nz = parseInt(filename[2])

  // Get cellStr and cellSize
  if (filename.length > 3) {
    const fileSize = filename[filename.length - 1]

    let cellStr = fileSize
    if (isNaN(fileSize)) {
      const fileSize_array = fileSize.split('')
      let splitString = ''

      fileSize_array.forEach((item) => {
        if (isNaN(item)) {
          splitString = splitString + item
        }
      })
      cellStr = fileSize.replace(splitString, '.').slice(0)
    }
    if (isNaN(cellStr)) {
      cellStr = fileSize.replace('p', '.').slice(0, -2)
    }
    let defaultUnit = fileSize.slice(-2)
    let cellSize = parseFloat(cellStr.replace(/^0+/, ''), 10)
    if (defaultUnit === 'mm') {
      cellSize *= 1000
    }
    if (isNaN(cellSize) || cellSize < 0) {
      cellSize = 0
    }
    if (cellSize > 1000) {
      cellSize = 1000
    }
    newState.cellStr = cellSize.toString()
    newState.cellSize = cellSize
  } else {
    newState.cellSize = 1
    newState.cellStr = '1'
  }

  return newState
}
