import "./WorkflowBar.css"

import React, { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getProjectWorkflow } from '../actions/workflowSlice'
import WorkflowItem from './WorkflowBar/WorkflowItem'

const WorkflowBar = React.memo(({ projectId, images }) => {
  const dispatch = useDispatch()
  const workflows = useSelector((state) => state.workflow?.listWorkflow || [])
  const intervalRef = useRef()

  const isPlaying = useMemo(() => {
    for(let i = 0; i < workflows.length; i++) {
      const jobsWorkflow = workflows[i]?.jobs || {}
      const listImageInJob = Object.keys(jobsWorkflow)
      const isRunningImage = listImageInJob.some(_image => jobsWorkflow[_image]?.running)
      if(isRunningImage) return true
    }
    return false
  }, [workflows])

  // console.log('workflows: ', workflows)

  useEffect(() => {
    if(!projectId) return
    // console.log('projectId: ', projectId)
    dispatch(getProjectWorkflow({ projectId }))
    intervalRef.current = null
  }, [projectId, dispatch])

  useEffect(() => {
    if(isPlaying) {
      if(intervalRef.current) return

      intervalRef.current = setInterval(() => dispatch(getProjectWorkflow({ projectId })), 4000)
    } else {
      if(!intervalRef.current) return

      clearInterval(intervalRef.current)
      intervalRef.current = null
    
    }
  }, [dispatch, isPlaying, projectId])

  // const pollApi = useCallback(() => {

  //   dispatch(getProjectWorkflow(projectId))
  // }, [dispatch, projectId])

  if(!workflows.length) return

  return (
    <div className="workflowBar">
      {
        workflows.map(workflowItem => {
          return (
            <WorkflowItem 
              images={images} 
              key={workflowItem._id}
              workflowInfo={workflowItem}
            />
          )
        })
      }
    </div>
  )
})

export default WorkflowBar
