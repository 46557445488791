import { useDragOver } from "@minoru/react-dnd-treeview"
import React from "react"

import TreeFolderItem from "./TreeFolderItem"
import TreeImageItem from "./TreeImageItem"

const CustomTreeNode = (props) => {
  const { isOpen, onToggle, hasChild } = props
  const { id, isImage, type } = props.node

  const indent = props.depth * 20

  const handleToggle = (e) => {
    e.stopPropagation()
    onToggle(id)
  }

  const dragOverProps = useDragOver(id, isOpen, onToggle)
  const isHasChildExpandedWrapper = hasChild || (!isImage && !hasChild)
  const isFolder = type === "folder"

  if (isFolder) {
    return (
      <TreeFolderItem
        {...props}
        dragOverProps={dragOverProps}
        handleToggle={handleToggle}
        indent={indent}
        isHasChildExpandedWrapper={isHasChildExpandedWrapper}
      />
    )
  }

  return (
    <TreeImageItem
      {...props}
      dragOverProps={dragOverProps}
      handleToggle={handleToggle}
      indent={indent}
      isHasChildExpandedWrapper={isHasChildExpandedWrapper}
    />
  )
}

export default CustomTreeNode
