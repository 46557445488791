import React from "react"

import { planeMap } from "../utils/images"

export const PlaneSelector = ({ currentCutPlane, updatePlane, disabled }) => {
  const onChange = (e) => {
    const planeIndex = parseInt(e.currentTarget.value)
    updatePlane({
      pos: planeIndex,
      face: planeMap[planeIndex]
    })
  }

  return (
    <form className="radio-holder">
      <label className="radio">
        <input
          checked={!disabled && currentCutPlane.pos === 0}
          onChange={onChange}
          type="radio"
          value={0}
        />
      </label>
      <label className="radio">
        <input
          checked={!disabled && currentCutPlane.pos === 1}
          onChange={onChange}
          type="radio"
          value={1}
        />
      </label>
      <label className="radio">
        <input
          checked={!disabled && currentCutPlane.pos === 2}
          onChange={onChange}
          type="radio"
          value={2}
        />
      </label>
    </form>
  )
}
