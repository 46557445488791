import { useEffect, useState } from "react"

const useDebounceFn = (func, delay) => {
  const [debouncedFunction, setDebouncedFunction] = useState(() => func)

  useEffect(() => {
    // Update debounced function after the delay
    const handler = setTimeout(() => {
      setDebouncedFunction(() => func)
    }, delay)

    // Cleanup the timeout on unmount or when dependencies change
    return () => {
      clearTimeout(handler)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay])

  return debouncedFunction
}

export default useDebounceFn
