import "./Parameters.css"

import Slider from "@mui/material/Slider"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Col, Dropdown, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Progress } from "reactstrap"

import { doJob, getJobStatus, getProject, killJob } from "../actions/projectSlice"
import { LaunchButton } from "../components"
import ImageFolders from "../components/ImageFolders"
import {
  ADJUSTMENT_JOB,
  SEGMENTATION_JOB,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING
} from "../constants/jobnames"
import { tooltips } from "../constants/tooltips"
import { getLocalStorage, isAuth, setLocalStorage } from "../helpers/auth"
import ConsoleHelper from "../helpers/ConsoleHelper"
import useJobNotification from "../hooks/useJobNotification"
import { killJobFn, sendUpdateRequestFn } from "../utils/jobs"
import PrintImages from "./SegmentationTool/PrintImages"

const INPUT_TYPE = "16b"
const JOB_TYPE = SEGMENTATION_JOB
const JOB_PREFIX = "seg_"
const ADJUST_JOB_TYPE = ADJUSTMENT_JOB
const POLLING_TIME = 3000
const ADJUST_POLLING_TIME = 300
const PARAM_COLUMN = 6

function SegmentationTool(props) {
  const [model, setModel] = useState("StableST_v1.0")
  const [thresholdMin, setThresholdMin] = useState(parseFloat(getLocalStorage("threshold_min", 0.0)))
  const [thresholdMax, setThresholdMax] = useState(parseFloat(getLocalStorage("threshold_max", 0.0)))
  const [adjust, setAdjust] = useState(true)
  const [adjustValues, setAdjustValues] = useState([0, 0, 0])
  const [adjustDefaultValues] = useState(JSON.parse(getLocalStorage("default_adjust", "[0, 0, 0]")))
  const [selectedImage, setSelectedImage] = useState(null)
  const [readyToLaunch, setReadyToLaunch] = useState(false)
  const [outputName, setOutputName] = useState("")
  const [viewID, setViewID] = useState("")
  const [sliderResetCount, setSliderResetCount] = useState(0)
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })
  const [adjustJob, setAdjustJob] = useState({
    status: STATUS_UNKNOWN,
    progress: 0
  })
  const [zOption, setZOption] = useState({ pX: 0, pY: 0, sC: 1 })
  const [cutPlane, setCutPlane] = useState({ pos: 0, face: "xy" })
  const intervalId = useRef(null)
  const intervalIdAdjust = useRef(null)

  const memoizedImages = useMemo(()=>{
    return props.project.images
  },[JSON.stringify(props.project.images)])

  const memoJobType = useMemo(()=>{
    return props.jobType
  },[props.jobType])

  const memoPid = useMemo(()=>{
    return props.project?.pid || null
  },[props.project?.pid])


  const sendUpdateRequest = sendUpdateRequestFn(props.getJobStatus)

  const resetParameter = ({ min, max, mode, adjustment }) => {
    setThresholdMin(min || 0.0)
    setThresholdMax(max || 0.0)
    setModel(mode || "StableST_v1.0")

    let adjustValuesDefault = [0, 0, 0]
    if (adjustment?.pore_micro) adjustValuesDefault[0] = adjustment?.pore_micro
    if (adjustment?.pore_solid) adjustValuesDefault[1] = adjustment?.pore_solid
    if (adjustment?.micro_solid) adjustValuesDefault[2] = adjustment?.micro_solid
    setAdjustValues(adjustValuesDefault)
  }

  const pollApi = useCallback(() => {
    // ConsoleHelper("JobStatus Polling ", job)
    if (Object.keys(job).length) {
      if ((job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) || job.jid === undefined) {
        clearInterval(intervalId.current)
      } else {
        sendUpdateRequest(props.project.pid, viewID, job.jid, JOB_TYPE)
      }
    }
  }, [job, props, sendUpdateRequest, viewID])

  const isJobFinished = useRef(false)
  const isJobAdjustFinished = useRef(false)

  useEffect(() => {
    if (!isJobFinished.current && job.status === STATUS_FINISHED) {
      isJobFinished.current = true // warning dont remove with current, if you remove infinity get current project
      props.getProject(props.project.pid)
      clearInterval(intervalId.current)
      return
    }

    if (job?.status === STATUS_RUNNING || job?.status === STATUS_WAITING) {
      isJobFinished.current = false
      intervalId.current = setInterval(pollApi, POLLING_TIME)
    }

    return () => clearInterval(intervalId.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.status, pollApi])

  const changeView = useCallback(
    (index) => {
      clearInterval(intervalId.current)
      clearInterval(intervalIdAdjust.current)
      setViewID(index)
      const image = props.project.images[index]
      if (image.jobs !== undefined && image.jobs[JOB_TYPE] !== undefined) {
        const jobSegmentation = image.jobs[JOB_TYPE]
        const jobAdjustment = image.jobs?.adjustment
        setJob(jobSegmentation)
        resetParameter({
          min: jobSegmentation?.parameters?.threshold_bot,
          max: jobSegmentation?.parameters?.threshold_top,
          mode: jobSegmentation?.parameters?.model,
          adjustment: jobAdjustment?.parameters
        })
      } else {
        setJob({ status: STATUS_UNKNOWN, progress: 0 })
        resetParameter({
          min: 0.0,
          max: 0.0,
          mode: "StableST_v1.0",
          adjustment: null
        })
      }

      setSelectedImage(image)
      setOutputName(JOB_PREFIX + image.name)
      setAdjust(false)
      // setAdjustValues()
      setSliderResetCount(sliderResetCount + 1)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adjustDefaultValues, pollApi, props, sendUpdateRequest, sliderResetCount]
  )

  const pollApiAdjust = () => {
    if (Object.keys(adjustJob).length) {
      sendUpdateRequest(props.project.pid, viewID, adjustJob.jid, ADJUST_JOB_TYPE)
    }
  }

  useEffect(() => {
    const images = props.project.images
    const nImages = Object.keys(images).filter((iid) => images[iid].type === INPUT_TYPE)
    if (nImages.length > 0) changeView(nImages[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // ConsoleHelper(" Updated component ", job)
    const image = props.project.images[viewID]
    if (image !== undefined && image.jobs !== undefined) {
      if (image.jobs[JOB_TYPE] !== undefined) {
        if (job.status !== image.jobs[JOB_TYPE].status || job.progress !== image.jobs[JOB_TYPE].progress) {
          setJob(image.jobs[JOB_TYPE])
        }
      }
    }
  }, [viewID, props?.project?.images, job])

  useJobNotification({ job })

  useEffect(() => {
    // ConsoleHelper(" Updated component ",job,adjustJob)
    const image = props.project.images[viewID]
    if (image !== undefined && image.jobs !== undefined) {
      if (image.jobs[ADJUST_JOB_TYPE] !== undefined) {
        setAdjustJob(image.jobs[ADJUST_JOB_TYPE])
      }
    }
  }, [viewID, props?.project?.images, adjustJob])

  useEffect(() => {
    if (!isJobAdjustFinished.current && adjustJob.status === STATUS_FINISHED) {
      isJobAdjustFinished.current = true // warning dont remove with current, if you remove infinity get current project
      props.getProject(props.project.pid)
      clearInterval(intervalIdAdjust.current)
      return
    }

    if (adjustJob?.status === STATUS_RUNNING || adjustJob?.status === STATUS_WAITING) {
      isJobAdjustFinished.current = false
      intervalIdAdjust.current = setInterval(pollApiAdjust, ADJUST_POLLING_TIME)
    }

    return () => clearInterval(intervalIdAdjust.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustJob])

  const updateZoom = (value) => {
    if (value !== undefined) {
      setZOption(value)
      // console.log('Zoom Updated', zOption)
    }
  }

  const checkLaunch = useCallback(() => {
    if (
      selectedImage !== null &&
      job.status !== STATUS_RUNNING &&
      job.status !== STATUS_WAITING &&
      outputName.length > 0
    ) {
      setReadyToLaunch(true)
    } else {
      setReadyToLaunch(false)
    }
  }, [job?.status, outputName?.length, selectedImage])

  useEffect(() => {
    checkLaunch()
  }, [selectedImage, job?.status, outputName, checkLaunch])

  const onChangeImage = useCallback((_value) => {
    changeView(_value)
  },[changeView])

  const onChangeOutput = (event) => {
    setOutputName(event.currentTarget.value)
  }

  const getLaunchProps = () => {
    let confimap = false
    if (adjust === true && job.status === STATUS_FINISHED) confimap = true
    return {
      model,
      thresholdMin,
      thresholdMax,
      adjust,
      adjustValues,
      confimap
    }
  }

  const launchJob = () => {
    let properties = getLaunchProps()
    properties = {
      ...properties,
      out_path: outputName
    }
    ConsoleHelper("Launching JoB", properties)
    let payload = {
      pid: props.project.pid,
      iid: selectedImage.iid,
      jobname: JOB_TYPE,
      data: properties
    }
    props.doJob(payload)
    intervalId.current = setInterval(pollApi, POLLING_TIME)
    setAdjustJob({ status: STATUS_UNKNOWN, progress: 0 })
    setAdjust(false)
  }

  const killJob = () => killJobFn(props.killJob)(props.project.pid, viewID, job.jid, JOB_TYPE, intervalId.current)

  const updateCutPlane = (value) => {
    if (value !== undefined) {
      setCutPlane(value)
      // console.log('Zoom Updated', cutPlane)
    }
  }

  const onChange = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), 1.0)

    if (e.target.id === "min") {
      setThresholdMin(number)
      setLocalStorage("threshold_min", number)
    }
    if (e.target.id === "max") {
      setThresholdMax(number)
      setLocalStorage("threshold_max", number)
    }
  }

  const onStopSlider = (newValue, index, iid, jid) => {
    ConsoleHelper("Adjusting from slider stop " + newValue + "/" + index)
    if (iid !== undefined && jid !== undefined) {
      let values = adjustValues
      values[index] = newValue[0]
      setAdjustValues(values)

      let dValues = adjustDefaultValues
      dValues[index] = newValue[0]
      setLocalStorage("default_adjust", dValues)

      let data = { values, parent_jid: jid, face: cutPlane.face }
      let payload = {
        pid: props.project.pid,
        jobname: ADJUSTMENT_JOB,
        iid,
        data
      }
      props.doJob(payload)
    }
  }

  const customMarks = (strMin, strMax) => {
    return [
      { value: -20, label: strMin },
      { value: 0, label: "" },
      { value: 20, label: strMax }
    ]
  }

  const SliderComponent = ({ disabled, index, iid, jid, adv }) => {
    let strMin = "Pore"
    let strMax = "Solid"
    if (index === 0) strMax = "Micro"
    if (index === 2) strMin = "Micro"
    let isRunning = adjustJob.status === STATUS_RUNNING || adjustJob.status === STATUS_WAITING
    let restrict = disabled || isRunning

    return (
      <React.Fragment>
        <div className="horizontal-slider-box">
          <Slider
            aria-labelledby="discrete-slider-always"
            defaultValue={[adv]}
            disabled={restrict}
            key={sliderResetCount}
            marks={customMarks(strMin, strMax)}
            max={20}
            min={-20}
            onChangeCommitted={(e, value) => onStopSlider(value, index, iid, jid)}
            orientation="horizontal"
            size="big"
            step={1}
          />
        </div>
      </React.Fragment>
    )
  }

  const AdjustComponent = ({ adv, iid, jid }) => {
    let localDisabled = !adjust
    return (
      <div
        className="parameters-holder"
        data-tooltip-content={tooltips.seg_p3}
        data-tooltip-delay-show={tooltips.delay_show_timeout}
      >
        <div className="inline-holder">
          <h4 className="subtitle" disabled={job.status !== STATUS_FINISHED}>
            Interactive Adjustment
            {(adjustJob.status === STATUS_RUNNING || adjustJob.status === STATUS_WAITING) && (
              <div className="dotloading">...</div>
            )}
          </h4>
          <div className="right-holder" id="seg-tool-p3">
            <div className="checkbox-holder">
              <div className="custom-control custom-checkbox">
                <input
                  checked={adjust}
                  className="custom-control-input"
                  disabled={job.status !== STATUS_FINISHED}
                  id="customCheck"
                  onChange={() => {
                    if (adjust) {
                      setAdjust(false)
                      setAdjustValues(adjustDefaultValues)
                    } else {
                      setAdjust(true)
                    }
                  }}
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor="customCheck" />
              </div>
            </div>
          </div>
        </div>
        <div className="parameter">
          {localDisabled ? null : (
            <>
              <SliderComponent adv={adv[0]} disabled={localDisabled} iid={iid} index={0} jid={jid} />
              <SliderComponent adv={adv[1]} disabled={localDisabled} iid={iid} index={1} jid={jid} />
              <SliderComponent adv={adv[2]} disabled={localDisabled} iid={iid} index={2} jid={jid} />
            </>
          )}
        </div>
      </div>
    )
  }

  let jid = undefined
  let iid = undefined
  if (job.status === STATUS_FINISHED) {
    jid = job.jid
    iid = viewID
  }

  const printImageProps = useMemo(() => {
    return {
      JOB_TYPE: JOB_TYPE,
      cutPlane: cutPlane,
      job: job,
      pid: props.project.pid,
      updateCutPlane: updateCutPlane,
      updateZoom: updateZoom,
      user: isAuth().email,
      viewID: viewID,
      zOption: zOption
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zOption, props.project?.pid, cutPlane, job.status])

  return (
    <div className="toolTab">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p1}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p1"
            >
              <Col xs={PARAM_COLUMN}> Model </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-font custom-btn" size="sm" variant="info">
                    {model}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-font" style={{ margin: 0 }}>
                    <Dropdown.Item onClick={() => setModel("Stable_256")}> StableST_Old </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModel("StableST_v1")}> StableST_v1.0 </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModel("DevST&CB_v1.4")}> DevST&CB_v1.4 </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p2"
            >
              <Col xs={PARAM_COLUMN}> Min Norm </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"min"}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.01"
                  type="number"
                  value={thresholdMin}
                />
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p3"
            >
              <Col xs={PARAM_COLUMN}> Max Norm </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"max"}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.01"
                  type="number"
                  value={thresholdMax}
                />
              </Col>
            </Row>

            <ReactTooltip anchorId="seg-tool-p1" className="tooltip-msg" />
            <ReactTooltip anchorId="seg-tool-p2" className="tooltip-msg" />
            <ReactTooltip anchorId="seg-tool-p3" className="tooltip-msg" />
          </div>
          <h4 className="subtitle"> Input Image [{INPUT_TYPE}]</h4>
          <ImageFolders
            imageType={INPUT_TYPE}
            images={memoizedImages}
            jobType={JOB_TYPE}
            onChangeImage={onChangeImage}
            pid={memoPid}
            viewID={viewID}
          />
          <h4 className="subtitle"> Output Name</h4>
          <div className="parameter">
            <input
              className="filename"
              onChange={onChangeOutput}
              placeholder=" Output File Name"
              type="text"
              value={outputName}
            />
          </div>
        </div>
        <AdjustComponent adv={adjustValues} iid={iid} jid={jid} />
        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton
          adjust={adjust}
          jobStatus={job.status}
          killJob={killJob}
          launchJob={launchJob}
          readyToLaunch={readyToLaunch}
        />
        <ReactTooltip anchorId="seg-tool-p3" className="tooltip-msg" />
      </div>
      <div className="toolResultWrapper">
        <PrintImages {...printImageProps} />
      </div>
    </div>
  )
}

SegmentationTool.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired
  }).isRequired,
  doJob: PropTypes.func.isRequired,
  killJob: PropTypes.func.isRequired,
  getJobStatus: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired
}

export default connect(null, { doJob, killJob, getJobStatus, getProject })(SegmentationTool)
