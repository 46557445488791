import "./Parameters.css"

import React from "react"

import { ExpandableList } from "../ExpandableList"

const SIZE_DEFAULT = 0.02
const NetexParameters = ({ image = null, parameter, onChange, openAdvancedDefault }) => {
  const netex = parameter

  return (
    <ExpandableList
      ariaLabel="advanced netex options"
      label={"Advanced Options"}
      list={[
        {
          label: "Micro-Phase Porosity",
          value: netex.microPorosity ?? SIZE_DEFAULT,
          step: 0.01,
          min: 0,
          max: 1,
          onChange: (x) => onChange({ ...netex, microPorosity: x })
        }
      ]}
      openDefault={openAdvancedDefault}
    />
  )
}

export default NetexParameters
