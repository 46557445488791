import "./WorkflowItem.css"

import { TextField } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { Button, Collapse, Dropdown } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { Check, ChevronRight, Edit, Play, X } from "react-feather"
import { useDispatch } from "react-redux"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { Progress } from "reactstrap"

import { deleteWorkflow, runWorkflow, updateToMIWorkflow } from "../../actions/workflowSlice"
import { STATUS_FINISHED } from "../../constants/jobnames"
import { WORKFLOW_LABEL, WORKFLOW_TYPE } from "../../constants/workflow"
import MultiInputsProcess from "./MultiInputsProcess"

const WorkflowItem = React.memo(({ workflowInfo, images }) => {
  const projectId = workflowInfo.pid

  const dispatch = useDispatch()

  const isPlaying = useMemo(() => {
    const jobsWorkflow = workflowInfo?.jobs || {}
    const listImageInJob = Object.keys(jobsWorkflow)
    const isRunningImage = listImageInJob.some((_image) => jobsWorkflow[_image]?.running)
    if (isRunningImage) return true
    return false
  }, [workflowInfo])

  const [isExpand, setExpand] = useState(false)
  const [workflowType, setWorkflowType] = useState(WORKFLOW_TYPE.MULTI_INPUTS)

  const handleCollapseToggle = () => {
    setExpand(!isExpand)
  }

  const handlePlayToggle = () => {
    const payload = {
      wid: workflowInfo._id,
      projectId: workflowInfo.pid
    }
    dispatch(runWorkflow(payload))
  }

  const handleDeleteWorkflow = useCallback(() => {
    confirmAlert({
      title: "Deleting Workflow - " + workflowInfo.name,
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(deleteWorkflow({ wid: workflowInfo._id, projectId }))
        },
        {
          label: "No"
        }
      ]
    })
  }, [dispatch, workflowInfo._id, workflowInfo.name, projectId])

  const progress = useMemo(() => {
    const imagesInWorkflow = workflowInfo?.inputImages || []
    const totalTool = Object.keys(workflowInfo?.metaTools)
    let totalImageSuccess = 0
    imagesInWorkflow.forEach((_image) => {
      const outputImage = workflowInfo?.outputs?.[_image] || {}
      if (totalTool.every((_tool) => outputImage[_tool]?.status === STATUS_FINISHED)) {
        totalImageSuccess += 1
      }
    })

    return (totalImageSuccess / imagesInWorkflow.length) * 100 || 0
  }, [workflowInfo?.inputImages, workflowInfo?.metaTools, workflowInfo?.outputs])

  return (
    <div className="workflow">
      <div className="workflowInfo">
        <div className="workflowNameWrapper">
          <ChevronRight className={`arrowRight ${isExpand && " active"}`} onClick={handleCollapseToggle} />
          <WorkflowName
            isPlaying={isPlaying}
            name={workflowInfo?.name}
            projectId={workflowInfo?.pid}
            workflowId={workflowInfo?._id}
          />
        </div>

        <div className="processLineWrapper" hidden={!isPlaying}>
          <UseAnimations animation={loading} className="play-icon" hidden={!isPlaying} size={28} />
          <Progress className="mt-0 w-100" color="success" max="100" value={progress}>
            {" "}
            {progress}%
          </Progress>
        </div>

        <div className="workflowAction">
          <Dropdown>
            <Dropdown.Toggle className="dropdown-font custom-btn" variant="info">
              {WORKFLOW_LABEL[workflowType] || ""}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-font" style={{ margin: 0 }}>
              <Dropdown.Item onClick={() => setWorkflowType(WORKFLOW_TYPE.MULTI_INPUTS)}>
                {WORKFLOW_LABEL[workflowType] || ""}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* {isPlaying ? (
                            <Pause className="play-icon" onClick={handlePlayToggle} size={28} />
                        ) : (
                            <Play className="play-icon" onClick={handlePlayToggle} size={28} />
                        )} */}
          {/* <Play className="play-icon" onClick={handlePlayToggle} size={28} /> */}
          {!isPlaying && <Play className="play-icon" onClick={handlePlayToggle} size={28} />}
          <Button className="removeWorkflowButton" onClick={handleDeleteWorkflow} variant="alert">
            <i className="fa fa-times fa-fw" />
          </Button>
        </div>
      </div>

      <Collapse in={isExpand}>
        <div className="workflowBody">
          <div className="processWrapper">
            {workflowType === WORKFLOW_TYPE.MULTI_INPUTS && (
              <MultiInputsProcess images={images} isPlaying={isPlaying} workflowInfo={workflowInfo} />
            )}
          </div>
        </div>
      </Collapse>
    </div>
  )
})

const WorkflowName = React.memo(({ workflowId, projectId, name = "", isPlaying = false }) => {
  const dispatch = useDispatch()

  const [isEdit, setEdit] = useState(false)
  const [txtName, setName] = useState(name)

  const handleCancel = () => {
    setName(name)
    setEdit(false)
  }

  const handleSubmit = () => {
    dispatch(updateToMIWorkflow({ wid: workflowId, dataUpdate: { name: txtName }, projectId }))
    setEdit(false)
  }

  if (isEdit) {
    return (
      <div className="workflowName">
        <TextField defaultValue={txtName} onChange={(e) => setName(e.target.value)} size="small" />
        <div className="workflowNameAction">
          <Check active={"t"} className="icon icon-red" onClick={handleSubmit} size={16} />
          <X className="icon icon-green" onClick={handleCancel} size={18} />
        </div>
      </div>
    )
  }

  return (
    <div className="workflowName">
      {name}
      <Edit className="icon icon-green" hidden={isPlaying} onClick={() => setEdit(true)} size={16} />
    </div>
  )
})

export default WorkflowItem
