import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Progress } from "reactstrap"
import { cancelUpload, setUploadID, uploadImage } from "../actions/projectSlice"
import { STATUS_RUNNING, STATUS_UNKNOWN, STATUS_WAITING, UPLOAD_JOB } from "../constants/jobnames"
import { getExt, getPropertiesFromFilename } from "../utils/filename"

const InputUpload = (props) => {
  const dispatch = useDispatch()

  const project = props.project
  const [state, setState] = useState({
    selectedFiles: null,
    loaded: 0,
    inputFile: "",
    nx: 1,
    ny: 1,
    nz: 1,
    cellSize: 1,
    cellStr: "1",
    type: "16b",
    bigEndian: false,
    extension: ""
  })
  const [readyToUpload, setReadyToUpload] = useState(false)
  const [uploadJob, setUploadJob] = useState({
    status: STATUS_UNKNOWN,
    progress: 0,
    cancelToken: null
  })

  const uploadID = useSelector((state) => state.project.uploadID)

  useEffect(() => {
    if (
      state.inputFile.length &&
      state.nx &&
      state.ny &&
      state.nz &&
      state.cellSize &&
      state.type.length > 0 &&
      state.extension.length > 0
    )
      setReadyToUpload(true)
    else setReadyToUpload(false)

    // console.log('Uploading Effect', uploadID)
    if (
      uploadID !== undefined &&
      project.images[uploadID] !== undefined &&
      project.images[uploadID].jobs !== undefined &&
      project.images[uploadID].jobs[UPLOAD_JOB] !== undefined
    ) {
      setUploadJob(project.images[uploadID].jobs[UPLOAD_JOB])
    }
    if (project.images[uploadID] === undefined) {
      setUploadJob({ status: STATUS_UNKNOWN, progress: 0 })
      if (uploadID !== undefined) dispatch(setUploadID(undefined))
    }
  }, [state, setReadyToUpload, setUploadJob, dispatch, project, uploadID])

  const onChangeFile = (event) => {
    let files = event.currentTarget.files
    if (files.length > 0) {
      const filename = files[0].name
      const extension = getExt(filename).toUpperCase()
      if (extension === "RAW") {
        const newState = getPropertiesFromFilename(filename)

        setState({
          ...state,
          selectedFiles: files,
          inputFile: filename,
          loaded: 0,
          extension,
          ...newState
        })
        return
      } else {
        toast.error("Images should be RAW")
      }
    }
    setState({ ...state, inputFile: "" })
  }

  const onChangeSize = (event) => {
    let number = parseInt(event.target.value.replace(/^0+/, ""), 10)
    if (isNaN(number)) number = 0
    if (event.target.id === "0") {
      number = Math.min(Math.max(number, 0), 5000)
      setState({ ...state, nx: number, ny: number, nz: number })
    }
    if (event.target.id === "1") {
      number = Math.min(Math.max(number, 0), 5000)
      setState({ ...state, ny: number })
    }
    if (event.target.id === "2") {
      number = Math.min(Math.max(number, 0), 10000)
      setState({ ...state, nz: number })
    }
  }

  const onChangeResolution = (event) => {
    let cellStr = event.target.value
    let cellSize = parseFloat(event.target.value.replace(/^0+/, ""), 10)
    if (isNaN(cellSize) || cellSize < 0) {
      cellSize = 0
      cellStr = "0"
    }
    if (cellSize > 1000) {
      cellSize = 1000
      cellStr = "1000"
    }
    setState({ ...state, cellSize, cellStr })
  }

  const onSubmitHandler = () => {
    const iid = state.selectedFiles[0].name.split(".").join("_")
    let nx = state.nx
    let ny = state.ny
    let nz = state.nz

    let bites = 1
    if (state.type === "16b") bites = 2
    if (state.type === "32b") bites = 4
    let size = nx * ny * nz * bites
    if (state.selectedFiles[0].size !== size) {
      let newZ = state.selectedFiles[0].size / (state.nx * state.ny * bites)
      if (!isNaN(newZ)) {
        nz = parseInt(newZ)
        toast.info(
          "Image size do not match file size. Changing Z to " +
            nz +
            ". Please use the edit menu if this is not correct."
        )
        setState({ ...state, nz })
      }
    }
    let newImage = {
      iid,
      filename: state.selectedFiles[0].name,
      name: state.selectedFiles[0].name,
      nx,
      ny,
      nz,
      cellSize: state.cellSize,
      type: state.type,
      extension: "RAW",
      bigEndian: state.bigEndian
    }
    // Copy file
    const data = new FormData()
    for (var x = 0; x < state.selectedFiles.length; x++) data.append("file", state.selectedFiles[x])
    data.append("newImage", JSON.stringify(newImage))

    const payload = {
      pid: project.pid,
      iid,
      newImage,
      data
    }

    dispatch(uploadImage(payload))
      .catch(() => {
      })

    props.changeView(iid)
  }

  const onCancelHandler = () => {
    dispatch(cancelUpload())
  }

  const SubmitButton = () => {
    if (uploadJob.status !== STATUS_RUNNING && uploadJob.status !== STATUS_WAITING)
      return (
        <button className="btn btn-success btn-block" disabled={!readyToUpload} onClick={onSubmitHandler} type="button">
          {" "}
          Upload{" "}
        </button>
      )
    else
      return (
        <button className="btn btn-warning btn-block" onClick={onCancelHandler} type="button">
          {" "}
          Cancel{" "}
        </button>
      )
  }

  const onChangeEndian = () => {
    setState({ ...state, bigEndian: !state.bigEndian })
  }

  return (
    <div className="input-upload">
      <div className="input-upload-files">
        <input className="form-control" onChange={onChangeFile} type="file" />
      </div>

      <div className="input-upload-sizes">
        <div className="inline-holder">
          <label className="label"> X </label>
          <input
            className="number"
            id={0}
            onChange={onChangeSize}
            onFocus={(e) => {
              e.target.select()
            }}
            pattern="[0-9]"
            type="number"
            value={state.nx.toString()}
          />
          <label className="label"> Y </label>
          <input
            className="number"
            id={1}
            onChange={onChangeSize}
            onFocus={(e) => {
              e.target.select()
            }}
            pattern="[0-9]"
            type="number"
            value={state.ny.toString()}
          />
          <label className="label"> Z </label>
          <input
            className="number"
            id={2}
            onChange={onChangeSize}
            onFocus={(e) => {
              e.target.select()
            }}
            pattern="[0-9]"
            type="number"
            value={state.nz.toString()}
          />
          <label className="label"> [um] </label>
          <input
            className="number-float"
            onChange={onChangeResolution}
            onFocus={(e) => {
              e.target.select()
            }}
            step="0.001"
            type="number"
            value={state.cellStr}
          />
        </div>

        <div className="inline-holder">
          <Dropdown>
            <Dropdown.Toggle className="dropdown-font dropdown-type" id="dropdown-basic" variant="primary">
              {state.type}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-font" style={{ margin: 0 }}>
              <Dropdown.Item onClick={() => setState({ ...state, type: "8b" })}> 8 bits </Dropdown.Item>
              <Dropdown.Item onClick={() => setState({ ...state, type: "16b" })}> 16 bits </Dropdown.Item>
              <Dropdown.Item onClick={() => setState({ ...state, type: "32b" })}> 32 bits </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="checkbox-holder">
            <div className="custom-control custom-checkbox">
              <input
                checked={state.bigEndian}
                className="custom-control-input"
                id="customCheck"
                onChange={onChangeEndian}
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="customCheck">
                {" "}
                BigEndian{" "}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="input-upload-progress">
        <Progress color="success" max="100" value={uploadJob.progress}>
          {" "}
          {uploadJob.progress}%
        </Progress>
      </div>
      <SubmitButton />
    </div>
  )
}

export default InputUpload
