/* eslint-disable no-console */
const buildTreeFromPayload = (images) => {
  const tree = []
  const stack = []
  let remainingImages = [...images]
  const rootImages = images.filter(
    (image) => image.iidParent === null || image.iidParent === undefined || image.iidParent === image.iid
  )

  const rootImageIids = rootImages.map((e) => e.iid)
  const childrenImages = images.filter((image) => !rootImageIids.includes(image.iid))

  rootImages.forEach((image) => {
    const node = {
      iid: image.iid,
      iidParent: null,
      depth: 0,
      droppable: true,
      isImage: image.type !== "folder",
      text: image.name,
      type: image.type,
      jobs: image.jobs,
      parentNode: null
    }
    tree.push(node)
    stack.push(node)
    remainingImages = remainingImages.filter((e) => e.iid !== image.iid)
  })

  if (tree.length <= 0 && images.length > 0) {
    // eslint-disable-next-line no-console
    console.log("Error in Image Trees, no root images")
    return []
  }


  while (stack.length > 0) {
    const parentNode = stack.pop()
    const children = childrenImages.filter((image) => image.iidParent === parentNode.iid)
    children.forEach((image) => {
      const node = {
        iid: image.iid,
        iidParent: image.iidParent,
        depth: parentNode.depth + 1,
        droppable: true,
        isImage: image.type !== "folder",
        text: image.name,
        type: image.type,
        jobs: image.jobs,
        parentNode
      }
      tree.push(node)
      stack.push(node)
      remainingImages = remainingImages.filter((e) => e.iid !== image.iid)
    })
  }

  if (images.length !== tree.length) {
    // console.log("Error in Image Trees, not all images are in the tree")
    // console.log("Images: ", images)
    remainingImages.forEach((image) => {
      const node = {
        iid: image.iid,
        iidParent: null,
        depth: 0,
        droppable: true,
        isImage: image.type !== "folder",
        text: image.name,
        type: image.type,
        jobs: image.jobs,
        parentNode: null
      }

      tree.push(node)
    })

  }

  // Set IDs
  let count = 1
  tree.map((node) => {
    node.id = count++
  })

  // Set parent IDs
  tree.map((node) => {
    const parentNode = tree.find((pNode) => node.iidParent === pNode.iid)
    let parentID = 0
    if (parentNode) parentID = parentNode.id
    node.parent = parentID
  })

  // console.log("Tree with IDs: ", tree)
  return tree
}

const findNearestParentNode = (tree, node) => {
  let _node = node?.parentNode
  while (_node) {
    const _ = tree.find((node) => node.iid === _node.iid)
    if (_) return _
    _node = _node?.parentNode
  }
  return null
}

export const buildTreeImage = (payload, pid, allowType) => {
  let tree = buildTreeFromPayload(payload)
  tree = tree.filter((node) => {
    const isAllowNode = allowType.includes(node.type)
    return isAllowNode
  })
  const listNode = []
  let count = 1
  tree.forEach((node) => {
    const _node = findNearestParentNode(tree, node)
    if (_node) {
      node.parentNode = _node
    }
    listNode.push({ ...node, pid, id: count++ })
  })

  const listResult = []
  listNode.forEach((node) => {
    const parent = listNode.find((e) => e.iid === node.parentNode?.iid)
    listResult.push({ ...node, parent: parent?.id || 0 })
  })
  return listResult
}
