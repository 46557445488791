import { getBackendOptions, MultiBackend, Tree } from "@minoru/react-dnd-treeview"
import React, { memo, useEffect, useRef, useState } from "react"
import { DndProvider } from "react-dnd"
import { ChevronRight } from "react-feather"
import { CheckCircle, CloudOff, Eye, MinusCircle } from "react-feather"
import { Tooltip as ReactTooltip } from "react-tooltip"
import UseAnimations from "react-useanimations"
import activity from "react-useanimations/lib/activity"
import alert from "react-useanimations/lib/alertTriangle"
import infinity from "react-useanimations/lib/infinity"

import { STATUS_FAILED, STATUS_FINISHED, STATUS_KILLED, STATUS_RUNNING, STATUS_WAITING } from "../../constants/jobnames"
import { tooltips } from "../../constants/tooltips"
import { buildTreeImage } from "../../helpers/BuildTreeHelper"
import { useTreeOpenDefaultImage } from "../../hooks/screens/ImageFolders"
import styles from "../tree/CustomTree.module.css"
import { TypeIcon } from "../tree/TypeIcon"
import styleImageFolder from "./style.module.css"

const ImageFolders = (props) => {
  let { images = {}, imageType = null, pid = null } = props
  const [treeData, setTreeData] = useState([])
  const refTree = useRef(null)

  useEffect(() => {
    if (!pid) return
    const allowWithType = imageType ? [imageType, "folder"] : ["folder", "16b", "8b"]
    let _treeData = buildTreeImage(Object.values(images), pid, allowWithType)
    setTreeData(_treeData)
  }, [images, imageType, pid])

  const handleOpenTree = (listIds) => {
    refTree.current?.open(listIds)
  }

  const { handleCacheIds } = useTreeOpenDefaultImage(treeData, handleOpenTree)

  return (
    <div className="mt-3 mb-2 w-full">
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Tree
          classes={{
            root: styles.treeRoot
          }}
          initialOpen={true}
          onDrop={()=>{}}
          ref={refTree}
          render={(node, { depth, isOpen, onToggle, hasChild }) => {
            const _onToggle = (id) => {
              onToggle(id)
              handleCacheIds(id, !isOpen)
            }
            return (
              <Node
                depth={depth}
                hasChild={hasChild}
                imageFolderProps={props}
                isOpen={isOpen}
                node={node}
                onToggle={_onToggle}
              />
            )
          }}
          rootId={0}
          sort={false}
          tree={treeData}
        />
      </DndProvider>
    </div>
  )
}

const Node = (props) => {
  const { depth, hasChild, isOpen, node, onToggle, imageFolderProps } = props
  const indent = depth * 20

  const handleToggle = (e) => {
    e.stopPropagation()
    onToggle(node.id)
  }

  const isHasChildExpandedWrapper = hasChild || (!node.isImage && !hasChild)
  return (
    <div className={`nodeTree ${styleImageFolder.treeNodeImage} ${styles.root}`} style={{ paddingInlineStart: indent }}>
      {/* The collapse icon */}
      <div
        className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ""} ${
          isHasChildExpandedWrapper ? styles.isHasChild : ""
        }`}
      >
        {hasChild && (
          <div onClick={handleToggle}>
            <ChevronRight className="icon icon-grey" size={16} />
          </div>
        )}
        {!node.isImage && !hasChild && (
          <div onClick={handleToggle}>
            <ChevronRight className="icon icon-grey" size={16} />
          </div>
        )}
      </div>
      {/* Image Process */}
      <ImageItem
        image={node}
        jobType={imageFolderProps?.jobType}
        onChangeImage={imageFolderProps?.onChangeImage}
        viewID={imageFolderProps?.viewID}
      />
    </div>
  )
}

const ImageItem = (props) => {
  const { image, jobType, viewID, onChangeImage = () => {} } = props
  const JobIcon = () => {
    if (image.jobs !== undefined && image.jobs[jobType] !== undefined) {
      switch (image.jobs[jobType].status) {
        case STATUS_RUNNING: {
          return (
            <UseAnimations
              animation={activity}
              className="icon green-icon"
              data-tooltip-content={tooltips.status_running}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_running_" + image.iid}
              size={18}
            />
          )
        }
        case STATUS_FAILED: {
          return (
            <UseAnimations
              animation={alert}
              className="icon red-icon"
              data-tooltip-content={tooltips.status_failed}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_failed_" + image.iid}
              size={20}
            />
          )
        }
        case STATUS_FINISHED: {
          return (
            <CheckCircle
              className="icon green-icon"
              data-tooltip-content={tooltips.status_finished}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_finished_" + image.iid}
              size={18}
            />
          )
        }
        case STATUS_KILLED: {
          return (
            <MinusCircle
              className="icon gray-icon"
              data-tooltip-content={tooltips.status_killed}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_killed_" + image.iid}
              size={18}
            />
          )
        }
        case STATUS_WAITING: {
          return (
            <UseAnimations
              animation={infinity}
              className="icon green-icon"
              data-tooltip-content={tooltips.status_waiting}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_waiting_" + image.iid}
              size={18}
            />
          )
        }
        default: {
          return <CloudOff className="icon darkgray-icon" size={18} />
        }
      }
    } else {
      return <MinusCircle className="icon gray-icon" size={18} />
    }
  }

  const isFolder = image.type === "folder"
  const handleChangeImage = () => {
    if (isFolder) {
      return
    }
    onChangeImage(image.iid)
  }

  return (
    <label className={styleImageFolder.imageItem} key={image.iid}>
      <input
        checked={viewID === image.iid}
        hidden={true}
        id={image.iid}
        name="inlineMaterialRadiosExample"
        onChange={handleChangeImage}
        type="radio"
        value={image.iid}
      />
      {isFolder && (
        <div>
          <TypeIcon fileType={image.isImage} />
        </div>
      )}

      <p className={styleImageFolder.imageName}>{image.text}</p>

      <div className={styleImageFolder.rightIconWrapper}>
        <div className={styleImageFolder.rightIcon}>
          <Eye active={viewID === image.iid ? "t" : "f"} className="icon focus-red" onClick={() => {}} size={20} />
        </div>
        <div className="icons-holder">
          <div className={styleImageFolder.rightIcon}>
            <JobIcon />
          </div>
          <ReactTooltip anchorId={"status_failed_" + image.iid} className="tooltip-msg" />
          <ReactTooltip anchorId={"status_finished_" + image.iid} className="tooltip-msg" />
          <ReactTooltip anchorId={"status_killed_" + image.iid} className="tooltip-msg" />
          <ReactTooltip anchorId={"status_running_" + image.iid} className="tooltip-msg" />
          <ReactTooltip anchorId={"status_waiting_" + image.iid} className="tooltip-msg" />
        </div>
      </div>
    </label>
  )
}

export default memo(ImageFolders)
