import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import { getProjects } from "../actions/projectSlice"
import Project from "./Project"
import ProjectsGrid from "./ProjectsGrid"

class ProjectsPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.getProjects()
  }

  render() {
    return (
      <div className="bg-white p-2">
        {this.props.currentPID === "" || this.props.currentPID === undefined ? (
          <ProjectsGrid />
        ) : (
          <Project />
        )}
      </div>
    )
  }
}

ProjectsPage.propTypes = {
  currentPID: PropTypes.string.isRequired,
  getProjects: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  currentPID: state.project.currentPID,
})

export default connect(mapStateToProps, { getProjects })(ProjectsPage)
