import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const TooltipWrapper = ({ text, children }) => {
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    )
  }

  return (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={renderTooltip} placement="top-start">
      {children}
    </OverlayTrigger>
  )
}
export default TooltipWrapper
