import "./Landing.css"
import "./webflow.css"

import axios from "axios"
import React from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

import front from "../images/FrontImageScreen2.png"
import front500 from "../images/FrontImageScreen2-p-500.png"
import front800 from "../images/FrontImageScreen2-p-800.png"
import front1080 from "../images/FrontImageScreen2-p-1080.png"
import front1600 from "../images/FrontImageScreen2-p-1600.png"
import front2000 from "../images/FrontImageScreen2-p-2000.png"
import gallery_1 from "../images/gallery-1.png"
import gallery_2 from "../images/gallery-2.png"
import gallery_3 from "../images/gallery-3.png"
import gallery_4 from "../images/gallery-4.png"
import gallery_5 from "../images/gallery-5.png"
import gallery_6 from "../images/gallery-6.png"
import gallery_7 from "../images/gallery-7.png"
import gallery_8 from "../images/gallery-8.png"
// import tools from "../images/LandFrontertools2.png"
// import tools500 from "../images/LandFrontertools2-p-500.png"
// import tools800 from "../images/LandFrontertools2-p-800.png"
// import tools1080 from "../images/LandFrontertools2-p-1080.png"
// import tools1600 from "../images/LandFrontertools2-p-1600.png"
// import tools2000 from "../images/LandFrontertools2-p-2000.png"
// import tools2600 from "../images/LandFrontertools2-p-2600.png"

const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = (data, e) => {
    ConsoleHelper("Data", data)
    // --------------------------------------------------------
    // Send email from backend
    axios
      .post(`${process.env.REACT_APP_API_URL}/backend/message`, {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        message: data.message
      })
      .then(() => {})
      .catch((err) => {
        ConsoleHelper("Error in Messaging", err)
      })
    // --------------------------------------------------------
    toast.success("Thanks for your message!\n We'll contact as soon as possible ")
    e.target.reset()
  }

  return (
    <div className="main-container">
      <div className="main-image">
        <img
          alt=""
          className="image-14"
          loading="lazy"
          sizes="100vw"
          src={front}
          srcSet={`${front500} 500w, ${front800} 800w, ${front1080} 1080w, ${front1600} 1600w, 
          ${front2000} 2000w, ${front} 2002w`}
        />
      </div>


      <div className="section-container">
        <div className="container-fluid">
          <h1 className="section-title">The Digital Rock Toolbox</h1>
          <p className="section-description">
            All the tools you need in a single place. Process your 2D and 3D images, 
            create representative models, and calculate rock properties
          </p>
        </div>
      </div>


      <div className="gallery-container">
        <div className="container grid-gallery">
          <div className="gallery-item">
            <img src={gallery_1} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_2} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_3} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_4} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_5} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_6} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_7} alt="" />
          </div>
          <div className="gallery-item">
            <img src={gallery_8} alt="" />
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="container-fluid">
          <h1 className="section-title">
            A complete digital rock solution - Brought to the cloud for your
            convenience
          </h1>
        </div>
      </div>

      <div className="container-cloud" id="cloudben">
        <div className="container-fluid cloud-grid">
          <div className="cloud-description-container">
            <div className="cloud-description">
              <p className="cloud-description-text">
                Hardware, software and workflows 
                <br/>
                available online
              </p>
              <p className="cloud-description-text">
                Sign up. Log in. Process.
              </p>
            </div>
          </div>
          <img
              alt=""
              className="cloud-image"
              loading="lazy"
              src={require("../images/cloudplatform.png")}
            />
        </div>
      </div>

      <div className="section-container">
        <div className="container-fluid">
          <h1 className="section-title">Get started today</h1>
        </div>
      </div>
      
      <div className="contact-form" id="contact-form">
        <div className="container-contact">
          <p>
            {`
              If you would like more information about SmartRocks or you simply
              would like to start processing your digital data, complete the
              contact sheet below and we'll make sure your account is set up,
              activated and ready to go.
            `}
          </p>
          <div className="small-text" id="formInstructions">
            <em>Fields marked with an asterisk (*) are required.</em>
          </div>
          <div className="w-form">
            {/* <form onSubmit={handleSubmit}> */}
            <form name="contactform" onSubmit={handleSubmit(onSubmit)}>
              <div className="contact-form-grid">
                <div className="w-node-c93030f4fc8f-e4a441e1">
                  <label htmlFor="firstName" id="contact-first-name">
                    First name *
                  </label>
                  <input
                    className="w-input"
                    type="text"
                    {...register("firstName", {
                      required: true,
                      maxLength: 256
                    })}
                  />
                  {errors.firstName && <p className="error"> First Name is required </p>}
                </div>
                <div className="w-node-c93030f4fc93-e4a441e1">
                  <label htmlFor="lastName" id="contact-last-name">
                    Last name *
                  </label>
                  <input
                    className="w-input"
                    type="text"
                    {...register("lastName", {
                      required: true,
                      maxLength: 256
                    })}
                  />
                  {errors.lastName && <p className="error"> Last Name is required </p>}
                </div>
                <div className="w-node-c93030f4fc97-e4a441e1">
                  <label htmlFor="email" id="contact-email">
                    Email *
                  </label>
                  <input
                    className="w-input"
                    type="text"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    })}
                  />
                  {errors.email && <p className="error"> Invalid email address </p>}
                </div>
                <div className="w-node-c93030f4fc9b-e4a441e1">
                  <label htmlFor="company" id="contact-company">
                    Company *
                  </label>
                  <input className="w-input" type="text" {...register("company", { required: true, maxLength: 256 })} />
                  {errors.company && <p className="error"> Company Name is required </p>}
                </div>
              </div>
              <div className="w-node-c93030f4fc9f-e4a441e1">
                <label htmlFor="message" id="contact-message">
                  Message
                </label>
                <textarea
                  className="w-input"
                  {...register("message", {
                    required: true,
                    minLength: 10,
                    maxLength: 5000
                  })}
                />
                {errors.message && <p className="error">{'Message is required: min/max 10/5000"'}</p>}
              </div>
                <button
                    className="mt-4 btnSubmitContact"
                    type="submit"
                  >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="footer-dark">
        <footer>
          <div className="flex justify-center">
            <h3> SmartRocks ®</h3>
          </div>
          <div className="flex justify-center">
            <p>This project is supported by Norwegian Research Council (NRC), ENI AS, Repsol, Chevron Corporation.</p>
          </div>
          <p className="copyright">
            {" "}
            SmartRocks® / Power by <a href="https://petricore.com">Petricore</a> © 2021. All rights reserved.
          </p>
        </footer>
      </div>

    </div>
  )
}

export default Landing
