import "react-toastify/dist/ReactToastify.css"
import "./App.css"

import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import { isAuth } from "./helpers/auth"
import SessionContext from "./helpers/SessionContext"
import AdminRoute from "./routes/AdminRoute"
import PrivateRoute from "./routes/PrivateRoute"
// import Register from "./screens/auth/Register";
import Activate from "./screens/auth/Activate"
import Admin from "./screens/auth/Admin"
import ForgotPassword from "./screens/auth/ForgotPassword"
import Login from "./screens/auth/Login"
import ResetPassword from "./screens/auth/ResetPassword"
import Settings from "./screens/auth/Settings"
import Header from "./screens/Header"
import Landing from "./screens/Landing"
import ProjectsPage from "./screens/ProjectsPage"

const App = () => {
  const [session, setSession] = useState(false)

  useEffect(() => {
    isAuth() ? setSession(true) : setSession(false)
  }, [session])

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      <div
        className="App min-h-screen"
        style={
          isAuth()
            ? { backgroundColor: "white" }
            : { backgroundColor: "rgb(242, 245, 248)" }
        }
      >
        <Router>
          <Header />
          <ToastContainer />
          <Routes>
            <Route element={<Landing />} path="/" />
            <Route element={<Login />} path="/login" />
            {/* <Route path="/register" component={Register} /> */}
            <Route element={<ForgotPassword />} path="/users/password/forgot" />
            <Route
              element={<ResetPassword />}
              path="/users/password/reset/:token"
            />
            <Route element={<Activate />} path="/users/activate/:token" />
            <Route
              element={
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              }
              path="/admin/*"
            />
            <Route
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
              path="/settings/*"
            />
            <Route
              element={
                <PrivateRoute>
                  <ProjectsPage />
                </PrivateRoute>
              }
              path="/projects"
            />
          </Routes>
        </Router>
      </div>
    </SessionContext.Provider>
  )
}

export default App
