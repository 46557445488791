/* eslint-disable react-hooks/exhaustive-deps */
import "./InputFile.css"
import "./Parameters.css"

import { getBackendOptions, getDescendants, MultiBackend, Tree } from "@minoru/react-dnd-treeview"
import { CssBaseline, ThemeProvider } from "@mui/material"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DndProvider } from "react-dnd"
import { FolderPlus } from "react-feather"
import { connect } from "react-redux"

import { createFolder, deleteImage, getJobStatus, getProject, updateImage } from "../actions/projectSlice"
import { CustomDragPreview } from "../components/tree/CustomDragPreview"
import styles from "../components/tree/CustomTree.module.css"
import { Placeholder } from "../components/tree/Placeholder"
import { theme } from "../components/tree/theme"
import { EXTRA_JOB, STATUS_RUNNING, STATUS_UNKNOWN, STATUS_WAITING } from "../constants/jobnames"
import { buildTreeImage } from "../helpers/BuildTreeHelper"
import ConsoleHelper from "../helpers/ConsoleHelper"
import { useTreeOpenDefaultImage } from "../hooks/screens/ImageFolders"
import useJobNotification from "../hooks/useJobNotification"
import CustomTreeNode from "./InputFile/CustomTreeNode"
import PrintDetails from "./InputFile/PrintDetails"
import PrintImages from "./InputFile/PrintImages"
import InputUpload from "./InputUpload"

const POLLING_TIME = 2000

const InputScreen = (props) => {
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState("")
  const [nx, setNx] = useState(0)
  const [ny, setNy] = useState(0)
  const [nz, setNz] = useState(0)
  const [cellSize, setCellSize] = useState(0)
  const [viewID, setViewID] = useState("")
  const [viewImage, setViewImage] = useState(undefined)
  const [extraJob, setExtraJob] = useState({
    status: STATUS_UNKNOWN,
    progress: 0
  })
  const [zOption, setZOption] = useState({ pX: 0, pY: 0, sC: 1 })
  const [cutPlane, setCutPlane] = useState({ pos: 0, face: "xy" })
  const [treeData, setTreeData] = useState([])
  const refTree = useRef(null)

  const intervalId = useRef(null)
  const first = useRef(null)

  const handleTreeUpdate = (newTree) => {
    setTreeData(newTree)
  }

  const handleDrop = (newTreeData) => {
    let parentItem = treeData.find((titem) => titem.id === newTreeData[0].parent)
    if (parentItem) {
      if ((!newTreeData[0].isImage && parentItem.isImage) || (newTreeData[0].isImage && parentItem.isImage)) return true
      else setTreeData(newTreeData)

      let data = {
        pid: props.project.pid,
        iid: newTreeData[0].iid,
        iidParent: parentItem.iid
      }
      props.updateImage(data)
    }
  }

  const sendUpdateRequest = useCallback(
    (iid, jid) => {
      // ConsoleHelper("Sending job request ", jid)
      if (iid !== "" && iid !== undefined && jid !== undefined) {
        const payload = {
          pid: props.project.pid,
          iid,
          jid,
          jobname: EXTRA_JOB
        }
        props.getJobStatus(payload)
      } else {
        ConsoleHelper("Not defined JID, [" + jid + "]")
      }
    },
    [props]
  )

  const pollExtraJob = useCallback(() => {
    if ((extraJob.status !== STATUS_RUNNING && extraJob.status !== STATUS_WAITING) || extraJob.jid === undefined) {
      clearPoolExtraJob()
    } else {
      sendUpdateRequest(viewID, extraJob.jid)
    }
  }, [extraJob?.jid, extraJob?.status, sendUpdateRequest, viewID])

  useEffect(() => {
    if (first.current) {
      first.current = true
    }
    updateTree()
    return () => {
      clearPoolExtraJob()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateTree()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project])

  useEffect(() => {
    let image = props.project.images[viewID]
    if (image !== viewImage) {
      if (image !== undefined) {
        setViewImage(image)
        setName(image.name)
        setNx(image.nx)
        setNy(image.ny)
        setNz(image.nz)
        setCellSize(image.cellSize)
      } else {
        setViewImage(image)
        setName("")
        setNx(0)
        setNy(0)
        setNz(0)
        setCellSize(0)
      }
    }

    if (image !== undefined && image.jobs !== undefined && image.jobs[EXTRA_JOB] !== undefined) {
      let job1 = image.jobs[EXTRA_JOB]
      if (extraJob.status !== job1.status || extraJob.progress !== job1.progress) {
        setExtraJob(job1)
      }
    } else if (extraJob.status !== STATUS_UNKNOWN) {
      setExtraJob({ status: STATUS_UNKNOWN, progress: 0 })
    }

    if ((extraJob.status === STATUS_RUNNING || extraJob.status === STATUS_WAITING) && !intervalId.current) {
      sendUpdateRequest(viewID, extraJob.jid)
      intervalId.current = setInterval(pollExtraJob, POLLING_TIME)
    }
    if (extraJob.status !== STATUS_RUNNING && extraJob.status !== STATUS_WAITING) {
      clearPoolExtraJob()
    }
  }, [viewID, viewImage, extraJob, props?.project?.images, pollExtraJob, sendUpdateRequest])

  useJobNotification({ job: extraJob })

  const updateTree = () => {
    let project = props.project
    const pid = project.pid
    if (!pid) return
    const images = project.images

    if (images !== undefined && Object.keys(images).length > 0) {
      const imageKeys = Object.keys(images)
      const indexImage = imageKeys.findIndex((key) => key === viewID)
      const indexViewImage = indexImage > -1 ? indexImage : 0
      onChangeView(imageKeys[indexViewImage])
    }

    const allowWithType = ["folder", "16b", "8b"]
    let _treeData = buildTreeImage(Object.values(images), pid, allowWithType)
    setTreeData(_treeData)
  }

  const clearPoolExtraJob = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = undefined
    }
  }

  const removeImage = (id) => {
    handleDelete(id)
  }

  const updateCutPlane = (value) => {
    if (value !== undefined) {
      setCutPlane(value)
    }
  }

  const onChangeView = (iid) => {
    // ConsoleHelper("change view ", iid)
    setViewID(iid)
    setEdit(false)
    setViewImage(undefined)
  }

  const onChangeName = (e) => {
    setName(e.target.value)
  }

  const updateZoom = (value) => {
    if (value !== undefined) {
      setZOption({ ...zOption, ...value })
    }
  }

  const onChangeSize = (e) => {
    let number = parseInt(e.target.value.replace(/^0+/, ""), 10)
    if (isNaN(number)) number = 0
    if (e.target.id === "0") {
      number = Math.min(Math.max(number, 0), 5000)
      setNx(number)
    }
    if (e.target.id === "1") {
      number = Math.min(Math.max(number, 0), 5000)
      setNy(number)
    }
    if (e.target.id === "2") {
      number = Math.min(Math.max(number, 0), 10000)
      setNz(number)
    }
  }

  const onChangeResolution = (e) => {
    let number = parseFloat(e.target.value.replace(/^0+/, ""), 10)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0), 1000)
    setCellSize(number)
  }

  const onCreateFolder = (iidParent) => {
    let fiid = "folder-" + Math.round(new Date().valueOf() * Math.random())

    let newTree = [...treeData]
    if (!iidParent) {
      newTree.push({
        depth: 0,
        droppable: true,
        id: treeData.length + 1,
        iid: fiid,
        iidParent: null,
        image: false,
        index: treeData.length + 1,
        isnew: true,
        parent: 0,
        parentIndex: 0,
        text: "Folder",
        type: "folder"
      })
    } else {
      let parent = treeData.find((item) => item.index === iidParent)
      newTree.push({
        depth: parent?.depth + 1,
        droppable: true,
        id: treeData.length + 1,
        iid: fiid,
        iidParent: parent?.iid,
        image: false,
        index: treeData.length + 1,
        isnew: true,
        parent: iidParent,
        parentIndex: parent?.index,
        text: "Folder",
        type: "folder"
      })
    }

    setTreeData(newTree)
  }

  const onChangeEdit = () => {
    let image = props.project.images[viewID]

    let changed = image.name !== name
    changed |= image.nx !== nx
    changed |= image.ny !== ny
    changed |= image.nz !== nz
    changed |= image.cellSize !== cellSize
    if (changed) {
      // Change Image Data
      if (edit) {
        let data = {
          pid: props.project.pid,
          iid: viewID,
          name: name,
          nx: nx,
          ny: ny,
          nz: nz,
          cellSize: cellSize
        }
        props.updateImage(data)
      }
    }
    setEdit(!edit)
  }

  const handleTextSubmit = (id, value) => {
    const newTree = treeData.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          text: value,
          isnew: false
        }
      }
      return node
    })

    handleTreeUpdate(newTree)

    let currentNode = treeData.find((item) => item.id === id)
    let parentNode = treeData.find((item) => item.id === currentNode.parent)

    let data = {
      pid: props.project.pid,
      iid: currentNode.iid,
      iidParent: parentNode?.iid || null,
      name: value
    }

    props.createFolder(data)
    props.getProject(props.project.pid) // TODO: Better add folder to images
  }

  const handleTextChange = (id, value) => {
    const newTree = treeData.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          text: value
        }
      }
      return node
    })

    handleTreeUpdate(newTree)

    let treeItem = treeData.find((titem) => titem.id === id)
    let data = {
      pid: props.project.pid,
      iid: treeItem?.iid,
      name: value
    }
    props.updateImage(data)
  }

  const handleTextCancel = (id, check) => {
    if (check) {
      const newTree = treeData.filter((node) => node.id !== id)
      handleTreeUpdate(newTree)
    } else {
      const newTree = treeData.map((node) => {
        if (node.id === id) {
          return {
            ...node
          }
        }
        return node
      })
      handleTreeUpdate(newTree)
    }
  }

  const handleRemoveMulti = async (nodeDelete) => {
    const asynchronousProcessing = async (callback, duration = 2000) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(callback())
        }, duration)
      })
    }

    for (let i = 0; i < nodeDelete.length; i++) {
      const dataDelete = {
        pid: props.project.pid,
        iid: nodeDelete[i].iid
      }
      await asynchronousProcessing(() => props.deleteImage(dataDelete), 50)
    }
  }

  const handleDelete = (id) => {
    const deleteIds = [id, ...getDescendants(treeData, id).map((node) => node.id)]
    let newTree = []
    let nodeDelete = []
    treeData.forEach((node) => {
      if (!deleteIds.includes(node.id)) {
        newTree.push(node)
      } else {
        nodeDelete.push(node)
      }
    })
    handleTreeUpdate(newTree)
    handleRemoveMulti(nodeDelete.reverse())
  }

  const handleOpenTree = (listIds) => {
    refTree.current?.open(listIds)
  }

  const { handleCacheIds } = useTreeOpenDefaultImage(treeData, handleOpenTree)

  const images = props.project?.images || {}
  const image = images?.[viewID]

  const printDetailsProps = useMemo(() => {
    return {
      cellSize: cellSize,
      edit: edit,
      image: image,
      name: name,
      nx: nx,
      ny: ny,
      nz: nz,
      onChangeEdit: onChangeEdit,
      onChangeName: onChangeName,
      onChangeResolution: onChangeResolution,
      onChangeSize: onChangeSize
    }
  }, [cellSize, edit, name, nx, ny, nz, JSON.stringify(image)])

  const printImageProps = useMemo(() => {
    return {
      projectId: props.project.pid,
      image: image,
      cutPlane: cutPlane,
      extraJob: extraJob,
      zOption: zOption,
      updateCutPlane,
      updateZoom
    }
  }, [
    JSON.stringify(image),
    props.project.pid,
    JSON.stringify(zOption),
    JSON.stringify(extraJob),
    JSON.stringify(cutPlane)
  ])

  return (
    <div className="toolTab input-file">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <div className="inline-holder">
            <h4 className="subtitle"> Input Images </h4>
            <div className="right-holder">
              <FolderPlus className="icon icon-green" onClick={() => onCreateFolder(0)} size={18} />
            </div>
          </div>
          <div className="parameter-tree">
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <Tree
                  canDrop={(tree, { dragSource, dropTargetId }) => {
                    if (dragSource?.parent === dropTargetId) {
                      return true
                    }
                  }}
                  classes={{
                    root: styles.treeRoot,
                    draggingSource: styles.draggingSource,
                    dropTarget: styles.dropTarget
                  }}
                  dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
                  dropTargetOffset={5}
                  insertDroppableFirst={false}
                  onDrop={handleDrop}
                  placeholderRender={(node, { depth }) => <Placeholder depth={depth} node={node} />}
                  ref={refTree}
                  render={(node, { depth, isOpen, onToggle, hasChild }) => {
                    const _onToggle = (id) => {
                      onToggle(id)
                      handleCacheIds(node.iid, !isOpen)
                    }
                    return (
                      <CustomTreeNode
                        depth={depth}
                        hasChild={hasChild}
                        isOpen={isOpen}
                        node={node}
                        onChangeView={onChangeView}
                        onCreateFolder={onCreateFolder}
                        onDelete={handleDelete}
                        onTextCancel={handleTextCancel}
                        onTextChanges={handleTextChange}
                        onTextSubmit={handleTextSubmit}
                        onToggle={_onToggle}
                        project={props.project}
                        removeImage={removeImage}
                        viewID={viewID}
                      />
                    )
                  }}
                  rootId={0}
                  sort={false}
                  tree={treeData}
                />
              </DndProvider>
            </ThemeProvider>
          </div>
        </div>

        <PrintDetails {...printDetailsProps} />
      </div>

      <div className="toolResultWrapper input-grid gridIOTab">
        <PrintImages {...printImageProps} />

        <InputUpload changeView={onChangeView} project={props.project} />
      </div>
    </div>
  )
}

InputScreen.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired
    //    images: PropTypes.array.isRequired,
  }).isRequired,
  getJobStatus: PropTypes.func.isRequired
}

export default connect(null, {
  createFolder,
  getJobStatus,
  deleteImage,
  getProject,
  updateImage
})(InputScreen)
