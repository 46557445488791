export const WORKFLOW_TYPE = {
    MULTI_INPUTS: 1
}

export const WORKFLOW_LABEL = {
    [WORKFLOW_TYPE.MULTI_INPUTS]: "Multiple Inputs"
}

export const WORKFLOW_ERRORS = {
    GET_WORKFLOW: 'GET_WORKFLOW',
    CREATE_WORKFLOW: 'CREATE_WORKFLOW',
    DELETE: 'DELETE',
    ADD_TOOL_TO_MI_WORKFLOW: 'ADD_TOOL_TO_MI_WORKFLOW',
    ADD_IMAGE_TO_MI_WORKFLOW: 'ADD_IMAGE_TO_MI_WORKFLOW',
    RUN_MI_WORKFLOW: 'RUN_MI_WORKFLOW',
}