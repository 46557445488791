import axios from "axios"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password1: "",
    password2: "",
    token: "",
    textChange: "Submit"
  })
  const { password1, password2, token } = formData
  const params = useParams()

  useEffect(() => {
    let token = params.token
    if (token && token !== formData.token) {
      setFormData({ ...formData, token })
    }
  }, [formData, params])

  const handleChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (password1 === password2 && password1 && password2) {
      setFormData({ ...formData, textChange: "Submitting" })
      axios
        .put(`${process.env.REACT_APP_API_URL}/backend/resetpassword`, {
          newPassword: password1,
          resetPasswordLink: token
        })
        .then((res) => {
          setFormData({
            ...formData,
            password1: "",
            password2: ""
          })
          toast.success(res.data.message)
        })
        .catch((err) => {
          if (err.response.data.errors) toast.error(err.response.data.errors)
          else if (err.response) {
            toast.error("Error connecting to Server - " + err.response.status + " " + err.response.statusText)
          } else {
            toast.error("Error: No connection to server. Contact support")
          }
        })
    } else {
      toast.error("Passwords don't matches")
    }
  }
  return (
    <div className="min-h-full text-gray-900 flex justify-center pt-4">
      <div
        className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow sm:rounded-lg 
        flex justify-center flex-1"
      >
        <div className="mt-4 flex flex-col items-center">
          <div className="w-full flex-1 mt-2 text-indigo-500">
            <h2 className="text-2xl xl:text-3xl font-extrabold">Reset Your Password</h2>
            <div className="w-full flex-1 mt-4 text-indigo-500">
              <form className="mx-auto max-w-xs relative " onSubmit={handleSubmit}>
                <input
                  className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white"
                  onChange={handleChange("password1")}
                  placeholder="password"
                  type="password"
                  value={password1}
                />
                <input
                  className="w-full mt-2 px-8 py-2 rounded-lg font-medium bg-gray-100 border
                    border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                    focus:border-gray-400 focus:bg-white"
                  onChange={handleChange("password2")}
                  placeholder="Confirm password"
                  type="password"
                  value={password2}
                />
                <button
                  className="mt-4 w-full py-3 tracking-wide font-semibold bg-indigo-500 
                    text-gray-100 rounded-lg hover:bg-indigo-700 transition-all duration-300 
                    ease-in-out flex items-center justify-center focus:shadow-outline 
                    focus:outline-none"
                  type="submit"
                >
                  <i className="fas fa-sign-in-alt  w-6  -ml-2" />
                  <span className="ml-3">Submit</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
