import React, { memo, useMemo } from "react"

import { STATUS_FINISHED } from "../../constants/jobnames"
import { isAuth } from "../../helpers/auth"
import ImageFrame from "../ImageFrame"
import ImagePreview from "../ImagePreview"
import OutputFrame from "./OutputFrame"

const PrintImages = ({ pid, viewID, jobType, job, images, files, cutPlane, zOption, updateCutPlane }) => {
  const user = isAuth().email

  const inputFileNames = useMemo(() => {
    const _images = images[viewID]?.jobs?.[jobType]?.parameters?.inputFileName || []
    const _jid = images[viewID]?.jobs?.[jobType]?.jid || undefined

    return _images.map(
      (imagePath) =>
        process.env.REACT_APP_API_URL +
        "/backend/" +
        user +
        "/" +
        pid +
        "/" +
        viewID +
        "/" +
        jobType +
        "/" +
        imagePath +
        "?train=true" +
        "&jid=" + _jid
    )
  }, [viewID, jobType, JSON.stringify(images)])

  const outputImageProps = useMemo(() => {
    return {
      job: job,
      jobType: jobType,
      pid: pid,
      user: user,
      viewID: viewID,
      cutPlane: cutPlane,
      zOption: zOption,
      updateCutPlane: updateCutPlane
    }
  }, [job, jobType, pid, user, viewID, cutPlane, zOption, updateCutPlane])

  const imageFrameProps = useMemo(() => {
    return {
      disabled: true,
      title: "Output"
    }
  }, [])
  return (
    <div>
      <h4> Results </h4>
      <div className="images-container">
        <ImagePreview
          cutPlane={cutPlane}
          files={files}
          inputFileNames={inputFileNames}
          title="Input"
          updateCutPlane={updateCutPlane}
          zOption={zOption}
        />
        {job.status === STATUS_FINISHED ? (
          <div className="imagePreviewWrapper">
            <OutputFrame {...outputImageProps} />
          </div>
        ) : (
          <div className="imagePreviewWrapper">
            <ImageFrame {...imageFrameProps} />
          </div>
        )}
      </div>
    </div>
  )
}
export default memo(PrintImages)
